import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  content: { width: 974, height: 594 },
};

export const layoutReducer = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setContent: (state, action) => {
      state.content = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase('RESET', () => initialState);
  },
});

export const { setContent } = layoutReducer.actions;
export default layoutReducer.reducer;
