import React, { Suspense, lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router';
import { ErrorBoundary } from '../../errorBoundaries';
import {
  breadcrumbLabels,
  supportQriosRoutes,
  breadcrumbLinks,
  supportQriosRoutesRelative,
} from '../routes';
import { mainRoutes } from '../../main';
import { SetNavigation } from '../../layout';
import { LoadingFlom } from '../../components';
import { CheckRole } from 'features/hoc';
import { supportQriosRoleRules } from '../hooks';

// lazyImports
const PageNotFound = lazy(() => import('../../error-pages/fragments/pageNotFound/PageNotFound'));
const LetsTalk = lazy(() => import('../letsTalk/fragments/letsTalk/LetsTalk'));
const LetsTalkDetails = lazy(() => import('../letsTalk/fragments/letsTalkDetails/LetsTalkDetails'));

function SupportQrios() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingFlom />}>
        <Routes>
          <Route
            path={supportQriosRoutesRelative.LETS_TALK_DETAILS}
            element={
              <CheckRole {...supportQriosRoleRules.letsTalk}>
                <LetsTalkDetails />
              </CheckRole>
            }
          />

          <Route
            path={supportQriosRoutesRelative.LETS_TALK}
            element={
              <CheckRole {...supportQriosRoleRules.letsTalk}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.letsTalk}
                  breadcrumbsLabels={breadcrumbLabels.letsTalk}
                  siderOpenedKey={mainRoutes.SUPPORT_QRIOS}
                  siderSelectedKey={supportQriosRoutes.LETS_TALK}
                >
                  <LetsTalk />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route path="/" element={<Navigate to={supportQriosRoutes.LETS_TALK} />} />

          <Route path={supportQriosRoutesRelative[404]} element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}

export default SupportQrios;
