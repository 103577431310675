import React from 'react';
import { Typography } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import styles from './TransferInfo.module.scss';
import { getPaymentMethod, getSource } from '../../helpers/transactionHelpers';
import { PhoneNumberFilterRedirect } from 'features/components';
import TransactionStatus from '../transactionStatus/TransactionStatus';
import TransactionType from '../transactionType/TransactionType';
import { supportRoutes } from '../../../routes';

function TransferInfo({ transaction }) {
  return (
    <div>
      <Typography.Title level={3}>Transfer info:</Typography.Title>
      <div className={styles.transferInfoGrid}>
        <Typography.Text className={styles.itemTitle} strong>
          ID:
        </Typography.Text>
        <Typography.Text className={styles.itemContent}>{transaction.id}</Typography.Text>

        <Typography.Text className={styles.itemTitle} strong>
          Date:
        </Typography.Text>
        <Typography.Text className={styles.itemContent}>
          {dayjs(transaction.created).format('D MMMM YYYY')}
        </Typography.Text>

        <Typography.Text className={styles.itemTitle} strong>
          Time:
        </Typography.Text>
        <Typography.Text className={styles.itemContent}>
          {dayjs(transaction.created).format('H:mm:ss')}
        </Typography.Text>

        <Typography.Text className={styles.itemTitle} strong>
          Sender:
        </Typography.Text>
        <div>
          <PhoneNumberFilterRedirect
            phoneNumber={transaction.senderPhoneNumber}
            path={supportRoutes.TRANSACTIONS}
          />
        </div>

        <Typography.Text className={styles.itemTitle} strong>
          Receiver:
        </Typography.Text>
        <div>
          <PhoneNumberFilterRedirect
            phoneNumber={transaction.receiverPhoneNumber}
            path={supportRoutes.TRANSACTIONS}
          />
        </div>

        <Typography.Text className={styles.itemTitle} strong>
          Receiver country:
        </Typography.Text>
        <Typography.Text className={styles.itemContent}>
          {transaction.receiverCountryCode}
        </Typography.Text>

        <Typography.Text className={styles.itemTitle} strong>
          Receiver carrier:
        </Typography.Text>
        <Typography.Text className={styles.itemContent}>
          {transaction.receiverCarrier}
        </Typography.Text>

        <Typography.Text className={styles.itemTitle} strong>
          Receipt Email:
        </Typography.Text>
        <Typography.Text className={styles.itemContent}>{transaction.receiptEmail}</Typography.Text>

        <Typography.Text className={styles.itemTitle} strong>
          Type:
        </Typography.Text>
        <div>
          <TransactionType type={transaction.transferType} />
        </div>

        <Typography.Text className={styles.itemTitle} strong>
          Product name:
        </Typography.Text>
        <Typography.Text className={styles.itemContent}>{transaction.productName}</Typography.Text>

        <Typography.Text className={styles.itemTitle} strong>
          Product SKU:
        </Typography.Text>
        <Typography.Text className={styles.itemContent}>{transaction.sku}</Typography.Text>

        <Typography.Text className={styles.itemTitle} strong>
          Amount:
        </Typography.Text>
        <Typography.Text className={styles.itemContent}>{transaction.amount} USD</Typography.Text>

        <Typography.Text className={styles.itemTitle} strong>
          Processing fee:
        </Typography.Text>
        <Typography.Text className={styles.itemContent}>
          {transaction.processingFee} USD
        </Typography.Text>

        <Typography.Text className={styles.itemTitle} strong>
          Status:
        </Typography.Text>
        <Typography.Text className={styles.itemContent}>
          <TransactionStatus status={transaction.status} />
        </Typography.Text>

        <Typography.Text className={styles.itemTitle} strong>
          Source:
        </Typography.Text>
        <Typography.Text className={styles.itemContent}>
          {getSource(transaction.senderType)}
        </Typography.Text>

        <Typography.Text className={styles.itemTitle} strong>
          Payment method:
        </Typography.Text>
        <Typography.Text className={styles.itemContent}>
          {getPaymentMethod(transaction.paymentMethodType)}
        </Typography.Text>

        <Typography.Text className={styles.itemTitle} strong>
          Sender IP:
        </Typography.Text>
        <Typography.Text className={styles.itemContent}>{transaction.senderIP}</Typography.Text>

        <Typography.Text className={styles.itemTitle} strong>
          Sender UUID:
        </Typography.Text>
        <Typography.Text className={styles.itemContent}>
          {transaction.senderUUID ? transaction.senderUUID : 'Guest user'}
        </Typography.Text>

        <Typography.Text className={styles.itemTitle} strong>
          Void:
        </Typography.Text>
        <Typography.Text className={styles.itemContent}>{`${transaction.void}`}</Typography.Text>

        {transaction.paymentProcessingInfo && (
          <>
            <Typography.Text
              className={[styles.itemTitle, styles.paymentResponseTitle].join(' ')}
              strong
            >
              Payment response:
            </Typography.Text>
            <div></div>
            <code className={styles.code}>
              <pre style={{ margin: 0, padding: 0 }}>
                {JSON.stringify(transaction.paymentProcessingInfo, null, 2)}
              </pre>
            </code>
            {transaction.paymentProcessingInfo.code !== '1' && (
              <>
                <Typography.Text
                  className={[styles.itemTitle, styles.paymentResponseTitle].join(' ')}
                  strong
                >
                  Payment error details:
                </Typography.Text>
                <Typography.Link
                  className={styles.itemContent}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://developer.authorize.net/api/reference/responseCodes.html?code=${transaction.paymentProcessingInfo.code}`}
                >
                  Authorize.net
                </Typography.Link>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

TransferInfo.propTypes = {
  transaction: PropTypes.object,
};

TransferInfo.defaultProps = {
  transaction: {},
};

export default TransferInfo;
