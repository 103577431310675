import React from 'react';
import { Form, Select } from 'antd';
import { transactionTypes } from '../../const';

function TransactionTypeFormItem() {
  return (
    <Form.Item name="transferType" label="Transaction type" initialValue="">
      <Select>
        <Select.Option value="">All</Select.Option>
        <Select.Option value={`${transactionTypes.topUp.value}`}>
          {transactionTypes.topUp.name}
        </Select.Option>
        <Select.Option value={`${transactionTypes.data.value}`}>
          {transactionTypes.data.name}
        </Select.Option>
        <Select.Option value={`${transactionTypes.bless.value}`}>
          {transactionTypes.bless.name}
        </Select.Option>
        <Select.Option value={`${transactionTypes.marketplace.value}`}>
          {transactionTypes.marketplace.name}
        </Select.Option>
        <Select.Option value={`${transactionTypes.membership.value}`}>
          {transactionTypes.membership.name}
        </Select.Option>
        <Select.Option value={`${transactionTypes.cash.value}`}>
          {transactionTypes.cash.name}
        </Select.Option>
        <Select.Option value={`${transactionTypes.creditPackage.value}`}>
          {transactionTypes.creditPackage.name}
        </Select.Option>
        <Select.Option value={`${transactionTypes.creditTransfer.value}`}>
          {transactionTypes.creditTransfer.name}
        </Select.Option>
        <Select.Option value={`${transactionTypes.sprayBless.value}`}>
          {transactionTypes.sprayBless.name}
        </Select.Option>
      </Select>
    </Form.Item>
  );
}

export default TransactionTypeFormItem;
