import { mainRoutes, generateAbsoluteRoutes } from '../main/routes';

export const discoverRoutesRelative = {
  404: '/*',
  PRODUCTS: '/products',
  PRODUCTS_DETAILS: '/products/:id',
  VIDEOS: '/videos',
  VIDEOS_DETAILS: '/videos/:id',
  PODCASTS: '/audio',
  PODCASTS_DETAILS: '/audio/:id',
  TEXT_STORIES: '/writings',
  TEXT_STORIES_DETAILS: '/writings/:id',
  EXPOS: '/video-stories',
  EXPOS_DETAILS: '/video-stories/:id',
  CATEGORY_MANAGEMENT: '/category-management',
  DETAILS: '/details/:id',
};

export const discoverRoutes = generateAbsoluteRoutes(discoverRoutesRelative, mainRoutes.DISCOVER);

export const discoverLabels = {
  [discoverRoutes.PRODUCTS]: 'Products',
  [discoverRoutes.VIDEOS]: 'Videos',
  [discoverRoutes.PODCASTS]: 'Audio',
  [discoverRoutes.TEXT_STORIES]: 'Writings',
  [discoverRoutes.EXPOS]: 'Expos',
  [discoverRoutes.CATEGORY_MANAGEMENT]: 'Categories',
};

export const breadcrumbLabels = {
  products: ['flom v1', discoverLabels[discoverRoutes.PRODUCTS]],
  videos: ['flom v1', discoverLabels[discoverRoutes.VIDEOS]],
  podcasts: ['flom v1', discoverLabels[discoverRoutes.PODCASTS]],
  textStories: ['flom v1', discoverLabels[discoverRoutes.TEXT_STORIES]],
  expos: ['flom v1', discoverLabels[discoverRoutes.EXPOS]],
  categoryManagement: ['flom v1', discoverLabels[discoverRoutes.CATEGORY_MANAGEMENT]],
};

export const breadcrumbLinks = {
  products: [undefined, discoverRoutes.PRODUCTS],
  videos: [undefined, discoverRoutes.VIDEOS],
  podcasts: [undefined, discoverRoutes.PODCASTS],
  textStories: [undefined, discoverRoutes.TEXT_STORIES],
  expos: [undefined, discoverRoutes.EXPOS],
  categoryManagement: [undefined, discoverRoutes.CATEGORY_MANAGEMENT],
};
