import React from 'react';
import { Typography, Card, Tag } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { arraysHaveSameElements } from 'core/helpers';
import { FlagFromPhoneNumber, RedirectFilterItem } from 'features/components';
import styles from './UsersListMobile.module.scss';
import { DeviceType, BlockStatus, FeaturedStatus, LoggedStatus } from '..';

function UsersListMobile({ users, onClick }) {
  return users.map((user) => {
    return (
      <Card
        className={styles.card}
        key={user.id}
        onClick={() => {
          onClick(user.id, user.username);
        }}
      >
        <div className={styles.gridContainer}>
          <Typography.Text className={styles.title} strong>
            Date:
          </Typography.Text>
          <Typography.Text>{dayjs(user.created).format('DD/MM/YY')}</Typography.Text>

          <Typography.Text className={styles.title} strong>
            Time:
          </Typography.Text>
          <Typography.Text>{dayjs(user.created).format('H:mm:ss')}</Typography.Text>

          <Typography.Text className={styles.title} strong>
            Username:
          </Typography.Text>
          <RedirectFilterItem content={user.username} redirectQuery="userOrPhone" />

          <Typography.Text className={styles.title} strong>
            Phone number:
          </Typography.Text>
          <div>
            <FlagFromPhoneNumber phoneNumber={user.phoneNumber} />
            <RedirectFilterItem content={user.phoneNumber} redirectQuery="userOrPhone" />
          </div>

          <Typography.Text className={styles.title} strong>
            Block status:
          </Typography.Text>
          <div>
            <BlockStatus status={user.blockedProductsLinked} />
          </div>

          <Typography.Text className={styles.title} strong>
            Featured:
          </Typography.Text>
          <div>
            <FeaturedStatus
              status={arraysHaveSameElements(user.featured?.types, [1, 2, 3, 4, 5])}
            />
          </div>

          <Typography.Text className={styles.title} strong>
            International user:
          </Typography.Text>
          <div>
            {user.internationalUser ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>}
          </div>

          <Typography.Text className={styles.title} strong>
            Device:
          </Typography.Text>
          <div>
            <DeviceType deviceType={user.deviceType} />
          </div>

          <Typography.Text className={styles.title} strong>
            Login:
          </Typography.Text>
          <div>
            <LoggedStatus hasLoggedIn={user.hasLoggedIn} />
          </div>
        </div>
      </Card>
    );
  });
}

UsersListMobile.propTypes = {
  users: PropTypes.array,
  onClick: PropTypes.func,
};

UsersListMobile.defaultProps = {
  users: [],
  onClick: () => {},
};

export default UsersListMobile;
