import React from 'react';
import { Collapse, Typography } from 'antd';
import PropTypes from 'prop-types';
import TribeDetails from './TribeDetails';

function TribesDetails({ tribeIds }) {
  if (tribeIds.length === 0) {
    return null;
  }
  return (
    <div className="grid-single-column">
      <Collapse ghost>
        <Collapse.Panel ghost header={<Typography.Text strong>Tribes:</Typography.Text>}>
          {tribeIds.map((tribeId) => {
            return <TribeDetails key={tribeId} tribeId={tribeId} />;
          })}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}

TribesDetails.propTypes = {
  tribeId: PropTypes.array,
};

TribesDetails.defaultProps = {
  tribeIds: [],
};

export default TribesDetails;
