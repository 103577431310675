import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

function LogOutUser() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'RESET' });
  }, []);

  return null;
}

export default LogOutUser;
