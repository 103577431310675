import { PhoneNumberUtil } from 'google-libphonenumber';

export const getCountryCodeFromNumber = (number) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    return phoneUtil.getRegionCodeForNumber(phoneUtil.parse(number));
  } catch {
    return '';
  }
};

export const isValidPhoneNumber = (phoneNumber) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phoneNumber));
  } catch {
    return false;
  }
};
