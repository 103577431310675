import React, { memo } from 'react';
import { Typography, Card } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import styles from './TransactionListMobile.module.scss';
import { PhoneNumberFilterRedirect } from 'features/components';
import TransactionStatus from '../transactionStatus/TransactionStatus';
import TransactionType from '../transactionType/TransactionType';

function TransactionListMobile({ items, onClick }) {
  return items.map((item) => {
    return (
      <Card
        className={styles.card}
        key={item.id}
        onClick={() => {
          onClick(item.id);
        }}
      >
        <div className={styles.gridContainer}>
          <Typography.Text className={styles.title} strong>
            Date:
          </Typography.Text>
          <Typography.Text>{dayjs(item.created).format('D MMMM YYYY')}</Typography.Text>

          <Typography.Text className={styles.title} strong>
            Time:
          </Typography.Text>
          <Typography.Text>{dayjs(item.created).format('H:mm:ss')}</Typography.Text>

          <Typography.Text className={styles.title} strong>
            Sender:
          </Typography.Text>
          <div>
            <PhoneNumberFilterRedirect phoneNumber={item.senderPhoneNumber} />
          </div>

          <Typography.Text className={styles.title} strong>
            Receiver:
          </Typography.Text>
          <div>
            <PhoneNumberFilterRedirect phoneNumber={item.receiverPhoneNumber} />
          </div>

          <Typography.Text className={styles.title} strong>
            Transaction ID:
          </Typography.Text>
          <Typography.Text>{item.id}</Typography.Text>

          <Typography.Text className={styles.title} strong>
            Type:
          </Typography.Text>
          <div>
            <TransactionType type={item.transferType} />
          </div>

          <Typography.Text className={styles.title} strong>
            Status:
          </Typography.Text>
          <div>
            <TransactionStatus status={item.status} />
          </div>

          <Typography.Text className={styles.title} strong>
            Amount:
          </Typography.Text>
          {item.amount && (
            <div>
              <Typography.Text>{item.amount}</Typography.Text>
              <Typography.Text> USD</Typography.Text>
            </div>
          )}
        </div>
      </Card>
    );
  });
}

TransactionListMobile.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  items: PropTypes.array,
  onClick: PropTypes.func,
};

TransactionListMobile.defaultProps = {
  isLoading: false,
  isError: false,
  items: [],
  onClick: () => {},
};

export default memo(TransactionListMobile);
