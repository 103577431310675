import React, { memo } from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';

function FeaturedStatus({ status }) {
  const featuredStatus = (status) => {
    switch (status) {
      case true:
        return <Tag color="green">Featured</Tag>;
      case false:
        return <Tag color="default">Normal</Tag>;
      case 'featured':
        return <Tag color="green">Featured</Tag>;
      case 'normal':
        return <Tag color="default">Normal</Tag>;
      default:
        return <Tag color="yellow">Unknown</Tag>;
    }
  };

  return featuredStatus(status);
}

FeaturedStatus.propTypes = {
  status: PropTypes.oneOf(['normal', 'featured', true, false]).isRequired,
};

export default memo(FeaturedStatus);
