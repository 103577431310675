import React, { Suspense, lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router';
import { ErrorBoundary } from 'features/errorBoundaries';
import { logsRoutes, logsRoutesRelative, breadcrumbLabels, breadcrumbLinks } from '../../routes';
import { mainRoutes } from 'features/main';
import { SetNavigation } from 'features/layout';
import { LoadingFlom } from 'features/components';
import { CheckRole } from 'features/hoc';
import { logsRoleRules } from '../../hooks';

//lazy imports
const ProductLogs = lazy(() => import('../../discoverLogs/fragments/productLogs/ProductLogs'));
const ProductLogDetails = lazy(() =>
  import('../../discoverLogs/fragments/productLogDetails/ProductLogDetails')
);
const Moderators = lazy(() => import('../../moderators/fragments/moderators/Moderators'));
const PaymentLogs = lazy(() => import('../../payment/fragments/paymentLogs/PaymentLogs'));

const PageNotFound = lazy(() => import('../../../error-pages/fragments/pageNotFound/PageNotFound'));

function Logs() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingFlom />}>
        <Routes>
          <Route
            path={logsRoutesRelative.DISCOVER_DETAILS}
            element={
              <CheckRole {...logsRoleRules.menu}>
                <ProductLogDetails />
              </CheckRole>
            }
          />

          <Route
            path={logsRoutesRelative.DISCOVER}
            element={
              <CheckRole {...logsRoleRules.menu}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.discover}
                  breadcrumbsLabels={breadcrumbLabels.discover}
                  siderOpenedKey={mainRoutes.LOGS_V1}
                  siderSelectedKey={logsRoutes.DISCOVER}
                >
                  <ProductLogs />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={logsRoutesRelative.MODERATORS}
            element={
              <CheckRole {...logsRoleRules.menu}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.moderators}
                  breadcrumbsLabels={breadcrumbLabels.moderators}
                  siderOpenedKey={mainRoutes.LOGS_V1}
                  siderSelectedKey={logsRoutes.MODERATORS}
                >
                  <Moderators />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={logsRoutesRelative.PAYMENT}
            element={
              <CheckRole {...logsRoleRules.menu}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.payment}
                  breadcrumbsLabels={breadcrumbLabels.payment}
                  siderOpenedKey={mainRoutes.LOGS_V1}
                  siderSelectedKey={logsRoutes.PAYMENT}
                >
                  <PaymentLogs />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route path="/" element={<Navigate to={logsRoutes.DISCOVER} />} />

          <Route path={logsRoutesRelative[404]} element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}

export default Logs;
