import React, { useEffect } from 'react';
import './App.scss';
import 'index.scss';
import { useSelector, useDispatch } from 'react-redux';
import ProtectedRoutes from './ProtectedRoutes';
import UnprotectedRoutes from './UnprotectedRoutes';
import { useWindowDimensions } from 'core/hooks';
import { screenBreakpoint } from 'core/helpers';
import { patchNavigation } from '../../layout/navigationSlice';

function App() {
  const authorization = useSelector((store) => store.authorization);
  const dispatch = useDispatch();
  const breakpoint = useWindowDimensions().width < screenBreakpoint;

  useEffect(() => {
    dispatch(patchNavigation({ breakpoint: breakpoint }));
  }, [dispatch, breakpoint]);

  if (authorization.authorized) {
    return <ProtectedRoutes />;
  }

  return <UnprotectedRoutes />;
}

export default App;
