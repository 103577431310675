import { mainRoutes, generateAbsoluteRoutes } from '../main/routes';

export const supportQriosRoutesRelative = {
  404: '/*',
  LETS_TALK: '/lets-talk',
  LETS_TALK_DETAILS: '/lets-talk/:id',
};

export const supportQriosRoutes = generateAbsoluteRoutes(
  supportQriosRoutesRelative,
  mainRoutes.SUPPORT_QRIOS
);

export const supportQriosLabels = {
  [supportQriosRoutes.LETS_TALK]: "Let's talk",
};

export const breadcrumbLabels = {
  letsTalk: ['Qrios', supportQriosLabels[supportQriosRoutes.LETS_TALK]],
};

export const breadcrumbLinks = {
  letsTalk: [undefined, supportQriosRoutes.LETS_TALK],
};
