import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const DEFAULT_OBSERVER_OPTIONS = {
  root: null,
  rootMargin: '40px',
  threshold: 0.5,
};

function LoadMore({ onMore = () => {}, className = 'w-1 h-1 opacity-0' }) {
  const loadingRef = useRef();

  const onIntersectHandler = (entries) => {
    if (entries && entries[0] && entries[0].isIntersecting) {
      onMore();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersectHandler, DEFAULT_OBSERVER_OPTIONS);

    observer.observe(loadingRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return <div ref={loadingRef} className={className} />;
}

LoadMore.propTypes = {
  onMore: PropTypes.func,
  className: PropTypes.string,
};

export default LoadMore;
