import { createSlice } from '@reduxjs/toolkit';
import { mergeArrays } from 'core/helpers/helperFunctions';

const initialState = {
  breadcrumbsLabels: [],
  breadcrumbsLinks: [],
  breadcrumbsStates: [],
  breadcrumbsDisabled: false,
  siderOpenedKeys: [],
  siderSelectedKey: '',
  breakpoint: false,
};

export const navigationReducer = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setBreadcrumbs: (state, action) => {
      state.breadcrumbsDisabled = action.payload.breadcrumbsDisabled;
      state.breadcrumbsLinks = action.payload.breadcrumbsLinks;
      state.breadcrumbsLabels = action.payload.breadcrumbsLabels;
      state.breadcrumbsStates = action.payload.breadcrumbsStates;
    },
    resetBreadcrumbs: (state) => {
      state.breadcrumbsDisabled = initialState.breadcrumbsDisabled;
      state.breadcrumbsLinks = initialState.breadcrumbsLinks;
      state.breadcrumbsLabels = initialState.breadcrumbsLabels;
      state.breadcrumbsStates = initialState.breadcrumbsStates;
    },
    addSiderOpenedKey: (state, action) => {
      if (!state.siderOpenedKeys.includes(action.payload)) {
        state.siderOpenedKeys = [...state.siderOpenedKeys, action.payload];
      }
    },
    addSiderOpenedKeys: (state, action) => {
      state.siderOpenedKeys = mergeArrays(state.siderOpenedKeys, action.payload);
    },
    patchNavigation: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase('RESET', (state) => ({ ...initialState, breakpoint: state.breakpoint }));
  },
});

export const {
  setBreadcrumbs,
  resetBreadcrumbs,
  addSiderOpenedKey,
  addSiderOpenedKeys,
  patchNavigation,
} = navigationReducer.actions;
export default navigationReducer.reducer;
