import React from 'react';
import { Layout as AntLayout } from 'antd';
import PropTypes from 'prop-types';
import styles from './Layout.module.scss';

import { Content, Header } from '../../components';

function AuthLayout({ children, header }) {
  return (
    <AntLayout className="w-screen h-screen">
      <Header {...header} />
      <AntLayout>
        <AntLayout className={styles.layout}>
          <Content breadcrumbs={{ disabled: true }}>{children}</Content>
        </AntLayout>
      </AntLayout>
    </AntLayout>
  );
}

AuthLayout.propTypes = {
  header: PropTypes.object,
};

AuthLayout.defaultProps = {};

export default AuthLayout;
