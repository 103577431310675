import { checkResponse, flomV1 } from 'core/api';
import { productTypes } from 'features/discover';

const linkUser = (user) => {
  let newUser = { ...user };
  newUser.blockedProductsLinked = user.blockedProducts ? 'blocked' : 'active';

  const featuredLinked = {
    video: 'normal',
    videoStory: 'normal',
    podcast: 'normal',
    textStory: 'normal',
    product: 'normal',
  };

  featuredLinked.video = user.featured.types.includes(productTypes.video.value)
    ? 'featured'
    : 'normal';
  featuredLinked.videoStory = user.featured.types.includes(productTypes.expo.value)
    ? 'featured'
    : 'normal';

  featuredLinked.podcast = user.featured.types.includes(productTypes.podcast.value)
    ? 'featured'
    : 'normal';
  featuredLinked.textStory = user.featured.types.includes(productTypes.textStory.value)
    ? 'featured'
    : 'normal';
  featuredLinked.product = user.featured.types.includes(productTypes.product.value)
    ? 'featured'
    : 'normal';

  newUser.featuredLinked = featuredLinked;

  return newUser;
};

const usersListLinking = (users) => {
  const linkedUsers = users.map((user) => linkUser(user));

  return linkedUsers;
};

export const getUsersList = async ({ queryKey }) => {
  const createQuery = (currentParams) => {
    let params = { page: currentParams.page };
    const searchParameters = new URLSearchParams();
    currentParams.page && searchParameters.append('page', currentParams.page);
    currentParams.deviceType && searchParameters.append('deviceType', currentParams.deviceType);
    currentParams.userNameChanged &&
      searchParameters.append('userNameChanged', currentParams.userNameChanged);
    currentParams.hasLoggedIn && searchParameters.append('hasLoggedIn', currentParams.hasLoggedIn);

    if (currentParams.userOrPhone) {
      if (/^\+/.test(currentParams.userOrPhone)) {
        params.phoneNumber = currentParams.userOrPhone;

        searchParameters.append('phoneNumber', currentParams.userOrPhone);
      } else {
        params.username = currentParams.userOrPhone;

        searchParameters.append('username', currentParams.userOrPhone);
      }
    }

    if (currentParams.all === 'featured') {
      const productKeys = Object.keys(productTypes);
      productKeys.forEach((productKey) => {
        searchParameters.append('featuredProductType', productTypes[productKey].value);
      });
    } else if (currentParams.all === 'normal') {
      const productKeys = Object.keys(productTypes);
      productKeys.forEach((productKey) => {
        searchParameters.append('normalProductType', productTypes[productKey].value);
      });
    } else {
      if (currentParams.podcast === 'featured') {
        searchParameters.append('featuredProductType', productTypes.podcast.value);
      }

      if (currentParams.podcast === 'normal') {
        searchParameters.append('normalProductType', productTypes.podcast.value);
      }

      if (currentParams.story === 'featured') {
        searchParameters.append('featuredProductType', productTypes.textStory.value);
      }

      if (currentParams.story === 'normal') {
        searchParameters.append('normalProductType', productTypes.textStory.value);
      }
    }

    if (currentParams.blockStatus === 'blocked') {
      searchParameters.append('blockedProducts', 1);
    } else if (currentParams.blockStatus === 'active') {
      searchParameters.append('blockedProducts', 0);
    }

    return searchParameters;
  };

  const adjustedParams = createQuery(queryKey[1]);

  const response = await flomV1.get('/api/v2/users', {
    params: adjustedParams,
  });
  if (checkResponse(response)) {
    const linkedUsers = usersListLinking(response.data.data.users);
    let linkedResponse = { ...response.data.data };
    linkedResponse.users = linkedUsers;
    return linkedResponse;
  }
};

export const getUserById = async ({ queryKey }) => {
  const { id } = queryKey[1];
  const response = await flomV1.get(`/api/v2/users/${id}`);
  if (checkResponse(response)) {
    const user = linkUser(response.data.data.user);
    const mobileData = response.data.data.mobileData;
    return { user, mobileData };
  }
};

export const patchUserFeaturedStatus = async ({ userId, status, productType }) => {
  const action = status === 'featured' ? 'add' : 'remove';

  const response = await flomV1.patch(`/api/v2/users/featured`, { userId, action, productType });

  if (checkResponse(response)) {
    return response.data.data;
  }
};

export const postUserFeaturedStatus = async ({ userId, productTypes, countryCode }) => {
  const response = await flomV1.post(`/api/v2/users/featured`, {
    userId,
    productTypes,
    countryCode,
  });

  if (checkResponse(response)) {
    return response.data.data;
  }
};

export const postUserFeaturedStatusAll = async ({ userId, value }) => {
  let productTypesAll,
    countryCode = null;
  if (typeof value === 'boolean' && value === true) {
    productTypesAll = [1, 2, 3, 4, 5];
  } else if (typeof value === 'boolean' && value === false) {
    productTypesAll = [];
  } else {
    countryCode = value;
  }

  const response = await postUserFeaturedStatus({
    userId,
    ...(productTypesAll && { productTypes: productTypesAll }),
    ...(countryCode && { countryCode }),
  });

  return response;
};

export const patchUserBlockStatus = async ({ userId, status }) => {
  const action = status === 'active' ? 'unblock' : 'block';
  const response = await flomV1.patch(`/api/v2/users/blocked`, { userId, action });

  if (checkResponse(response)) {
    return response.data.data;
  }
};

export const setInternationalStatus = async (userId, internationalUser) => {
  const response = await flomV1.patch(`/api/v2/users/international/${userId}`, {
    internationalUser,
  });

  if (checkResponse(response)) {
    return response.data.data;
  }
};
