import React, { Suspense, lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router';
import { ErrorBoundary } from 'features/errorBoundaries';
import { logsRoutes, logsRoutesRelative, breadcrumbLabels, breadcrumbLinks } from '../../routes';
import { mainRoutes } from 'features/main';
import { SetNavigation } from 'features/layout';
import { LoadingFlom } from 'features/components';
import { CheckRole } from 'features/hoc';
import { logsRoleRules } from '../../hooks';

//lazy imports
const PaymentLogs = lazy(() => import('../../payment/fragments/paymentLogs/PaymentLogs'));

const PageNotFound = lazy(() => import('../../../error-pages/fragments/pageNotFound/PageNotFound'));

function Logs() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingFlom />}>
        <Routes>
          <Route
            path={logsRoutesRelative.PAYMENT}
            element={
              <CheckRole {...logsRoleRules.menu}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.payment}
                  breadcrumbsLabels={breadcrumbLabels.payment}
                  siderOpenedKey={mainRoutes.LOGS}
                  siderSelectedKey={logsRoutes.PAYMENT}
                >
                  <PaymentLogs />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route path="/" element={<Navigate to={logsRoutes.PAYMENT} />} />

          <Route path={logsRoutesRelative[404]} element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}

export default Logs;
