import { AndroidFilled } from '@ant-design/icons';
import { mainLabels, mainRoutes } from 'features/main/routes';
import { usersLabels, usersRoutes } from './routes';
import { useShowMobileUsers } from './hooks';

export const useMobileUsersSider = () => {
  const showMobileUsers = useShowMobileUsers();

  if (showMobileUsers.menu) {
    return [
      {
        // type: 'subMenu',
        label: mainLabels[mainRoutes.MOBILE_USERS],
        key: mainRoutes.MOBILE_USERS,
        icon: <AndroidFilled />,
        children: [
          showMobileUsers.mobileUsers && {
            label: usersLabels[usersRoutes.USER_MANAGEMENT],
            key: usersRoutes.USER_MANAGEMENT,
          },
          showMobileUsers.merchantReview && {
            label: usersLabels[usersRoutes.MERCHANT_REVIEW],
            key: usersRoutes.MERCHANT_REVIEW,
          },
        ],
      },
    ];
  }

  return [];
};
