import React, { memo } from 'react';
import { Typography } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { arraysHaveSameElements } from 'core/helpers';
import { FlagFromPhoneNumber } from 'features/components';
import styles from './UsersListWeb.module.scss';
import {
  DeviceType,
  BlockStatus,
  FeaturedStatus,
  LoggedStatus,
  UsernameFilterItem,
  PhoneNumberFilterItem,
} from '..';

function UsersListWeb({ users, onClick }) {
  return (
    <>
      <table className={styles.table}>
        <thead>
          <tr>
            <th scope="col">
              <Typography.Title level={5}>Date:</Typography.Title>
            </th>
            <th scope="col">
              <Typography.Title level={5}>Time:</Typography.Title>
            </th>
            <th scope="col">
              <Typography.Title level={5}>Username:</Typography.Title>
            </th>
            <th scope="col">
              <Typography.Title level={5}>Phone number:</Typography.Title>
            </th>
            <th scope="col">
              <Typography.Title level={5}>Block status:</Typography.Title>
            </th>
            <th scope="col">
              <Typography.Title level={5}>Featured:</Typography.Title>
            </th>
            <th scope="col">
              <Typography.Title level={5}>Device:</Typography.Title>
            </th>
            <th scope="col">
              <Typography.Title level={5}>Login:</Typography.Title>
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr
              key={user.id}
              onClick={() => {
                onClick(user.id, user.username);
              }}
            >
              <td className={styles.center}>
                <Typography.Text>{dayjs(user.created).format('DD/MM/YY')}</Typography.Text>
              </td>
              <td className={styles.center}>
                <Typography.Text>{dayjs(user.created).format('H:mm:ss')}</Typography.Text>
              </td>
              <td className={styles.center}>
                <UsernameFilterItem content={user.username} />
              </td>
              <td className={styles.left}>
                <FlagFromPhoneNumber phoneNumber={user.phoneNumber} />
                <PhoneNumberFilterItem content={user.phoneNumber} username={user.username} />
              </td>
              <td className={styles.center}>
                <BlockStatus status={user.blockedProductsLinked} />
              </td>
              <td className={styles.center}>
                <FeaturedStatus
                  status={arraysHaveSameElements(user.featured?.types, [1, 2, 3, 4, 5])}
                />
              </td>
              <td className={styles.center}>
                <DeviceType deviceType={user.deviceType} />
              </td>
              <td className={styles.center}>
                <LoggedStatus hasLoggedIn={user.hasLoggedIn} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

UsersListWeb.propTypes = {
  users: PropTypes.array,
  onClick: PropTypes.func,
};

UsersListWeb.defaultProps = {
  users: [],
  onClick: () => {},
};

export default memo(UsersListWeb);
