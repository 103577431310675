import { useState, useEffect, useCallback } from 'react';

export function useWindowDimensions() {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const useContainerDimensions = () => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  };
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [containerDimensions, setContainerDimensions] = useState({ width: 0, height: 0 });

  const ref = useCallback(
    (node) => {
      if (node !== null) {
        const { width, height } = node.getBoundingClientRect();
        setContainerDimensions({ width, height });
      }
    },
    // eslint-disable-next-line
    [windowDimensions]
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return [ref, containerDimensions, windowDimensions];
};

// Using
// const MyComponent = ({children}) => {
//   const [myRef, containerDimensions, windowDimensions] = useClientRect();
//   const { width, height } = containerDimensions;
//   const { width, height } = windowDimensions;

//   return (
//     <div ref={myRef}>
//       <p>width: {width}px</p>
//       <p>height: {height}px</p>
//       {children}
//     <div/>
//   )
// }
