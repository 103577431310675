import React from 'react';
import { Collapse, Typography } from 'antd';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { LoadingSpinner } from 'features/components';
import { getTribeById } from '../../api/productsApi';

import styles from './TribesDetails.module.scss';

function TribeDetails({ tribeId }) {
  const tribe = useQuery(['Tribe', { tribeId: tribeId }], getTribeById, {
    retry: 0,
  });

  if (tribe.isLoading) {
    return <LoadingSpinner />;
  }

  if (tribe.isError || !tribe.data) {
    if (tribe.error.code === 443474) {
      return <Typography.Text>This tribe no longer exists.</Typography.Text>;
    }
    return <Typography.Text>Something went wrong, please try again later.</Typography.Text>;
  }

  const { name, description, numberOfMembers } = tribe.data;
  return (
    <Collapse ghost>
      <Collapse.Panel
        header={
          <Typography.Text className="justify-self-end" strong>
            {name}
          </Typography.Text>
        }
      >
        <div className={styles.textContainer}>
          <Typography.Text className="justify-self-end" strong>
            Description:
          </Typography.Text>
          <Typography.Text className={styles.tribeDescription}>{description}</Typography.Text>

          <Typography.Text className="justify-self-end" strong>
            Members:
          </Typography.Text>
          <Typography.Text>{numberOfMembers}</Typography.Text>
        </div>
      </Collapse.Panel>
    </Collapse>
  );
}

TribeDetails.propTypes = {
  tribeId: PropTypes.string,
};

TribeDetails.defaultProps = {
  tribeId: '',
};

export default TribeDetails;
