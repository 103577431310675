import React from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';

function ProcessingStatus({ mediaProcessingInfo = {} }) {
  const status = mediaProcessingInfo.status;

  switch (status) {
    case 'completed':
      return <Tag color="green">Completed</Tag>;
    case 'processing':
      return <Tag color="yellow">Processing</Tag>;
    case 'failed':
      return <Tag color="red">Failed</Tag>;
    default:
      return <Tag color="default">Unknown</Tag>;
  }
}

ProcessingStatus.propTypes = {
  mediaProcessingInfo: PropTypes.object,
};

export default ProcessingStatus;
