import React from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import styles from './Gallery.module.scss';
import { LoadingSpinner } from 'features/components';

function VideoPlayer({ url, isLoading, ...otherProps }) {
  return (
    <div className={styles.videoImageContainer}>
      <ReactPlayer
        {...otherProps}
        controls
        url={url}
        width="100%"
        height="100%"
        pip={false}
        config={{
          file: {
            attributes: {
              disablePictureInPicture: true,
              controlsList: 'nodownload noplaybackrate noremoteplayback',
            },
          },
        }}
      />
      {isLoading && (
        <div className={styles.spinner}>
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
}

VideoPlayer.propTypes = {
  url: PropTypes.string,
  isLoading: PropTypes.bool,
};

VideoPlayer.defaultProps = {
  url: '',
  isLoading: false,
};

export default VideoPlayer;
