import React from 'react';
import { Typography } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import FlagFromPhoneNumber from '../flagFromPhoneNumber/FlagFromPhoneNumber';

function PhoneNumberFilterRedirect({ phoneNumber, redirectQuery, path }) {
  const navigate = useNavigate();
  const location = useLocation();

  if (!phoneNumber) {
    return (
      <>
        <FlagFromPhoneNumber phoneNumber={phoneNumber} />
        <Typography.Text>Guest user</Typography.Text>
      </>
    );
  }

  const onClickHandler = (event) => {
    event.stopPropagation();
    navigate({
      pathname: path ? path : location.pathname,
      search: `?${redirectQuery}=${encodeURIComponent(phoneNumber)}`,
    });
  };

  return (
    <>
      <FlagFromPhoneNumber phoneNumber={phoneNumber} />
      <Typography.Link onClick={onClickHandler}>
        <u>{phoneNumber}</u>
      </Typography.Link>
    </>
  );
}

PhoneNumberFilterRedirect.propTypes = {
  phoneNumber: PropTypes.string,
  redirectQuery: PropTypes.string,
  path: PropTypes.string,
};

PhoneNumberFilterRedirect.defaultProps = {
  redirectQuery: 'phoneNumber',
};

export default PhoneNumberFilterRedirect;
