import { mainRoutes, generateAbsoluteRoutes } from 'features/main/routes';

export const adminUsersRoutesRelative = {
  USERS: '/users',
  USER_DETAILS: '/users/:id',
  CREATE_USER: '/create-user',
};

export const adminUsersRoutes = generateAbsoluteRoutes(
  adminUsersRoutesRelative,
  mainRoutes.ADMIN_USERS
);

export const adminUsersLabels = {
  [adminUsersRoutes.USERS]: 'Users list',
  [adminUsersRoutes.CREATE_USER]: 'Create user',
};

export const breadcrumbLabels = {
  users: ['flom v2', adminUsersLabels[adminUsersRoutes.USERS]],
  createUser: ['flom v2', adminUsersLabels[adminUsersRoutes.CREATE_USER]],
};

export const breadcrumbLinks = {
  users: [undefined, adminUsersRoutes.USERS],
  createUser: [undefined, adminUsersRoutes.CREATE_USER],
};
