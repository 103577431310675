import { mainRoutes, generateAbsoluteRoutes } from '../main/routes';

export const usersRoutesRelative = {
  404: '/*',
  USER_MANAGEMENT: '/users',
  USER_DETAILS: '/users/:id',
  MERCHANT_REVIEW: '/merchant-review',
  MERCHANT_REVIEW_DETAILS: '/merchant-review/:id',
};

export const usersRoutes = generateAbsoluteRoutes(usersRoutesRelative, mainRoutes.MOBILE_USERS);

export const usersLabels = {
  [usersRoutes.USER_MANAGEMENT]: 'Mobile users',
  [usersRoutes.MERCHANT_REVIEW]: 'Merchant review',
};

export const breadcrumbLabels = {
  userManagement: ['flom v1', usersLabels[usersRoutes.USER_MANAGEMENT]],
  merchantReview: ['flom v1', usersLabels[usersRoutes.MERCHANT_REVIEW]],
};

export const breadcrumbLinks = {
  userManagement: [undefined, usersRoutes.USER_MANAGEMENT],
  merchantReview: [undefined, usersRoutes.MERCHANT_REVIEW],
};
