import React, { useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import styles from './ReCaptcha.module.scss';

function ReCaptcha({ onChange, onErrored, value }) {
  const captchaRef = useRef();

  useEffect(() => {
    if (!value) {
      captchaRef.current.reset();
      onChange(undefined);
    }
  }, [value]);

  const onChangeHandler = (token) => {
    onChange(token);
  };

  const onExpiredHandler = () => {
    onChange(undefined);
  };

  const onErrorHandler = () => {
    onErrored();
  };

  return (
    <ReCAPTCHA
      ref={captchaRef}
      className={styles.captcha}
      sitekey={process.env.REACT_APP_MODE_RECAPTCHA_SITE_KEY}
      onChange={onChangeHandler}
      onExpired={onExpiredHandler}
      onErrored={onErrorHandler}
    />
  );
}

ReCaptcha.propTypes = {
  onChange: PropTypes.func,
  onErrored: PropTypes.func,
  value: PropTypes.string,
};

ReCaptcha.defaultProps = {
  onChange: () => {},
  onErrored: () => {},
  value: undefined,
};

export default ReCaptcha;
