import { mainRoutes, generateAbsoluteRoutes } from 'features/main/routes';

export const userManagementRoutesRelative = {
  SETTINGS: '/settings',
};

export const userManagementRoutes = generateAbsoluteRoutes(
  userManagementRoutesRelative,
  mainRoutes.USER_MANAGEMENT
);

export const userManagementLabels = { [userManagementRoutes.SETTINGS]: 'User settings' };

export const breadcrumbLabels = {
  settings: [userManagementLabels[userManagementRoutes.SETTINGS]],
};

export const breadcrumbLinks = { settings: [userManagementRoutes.SETTINGS] };
