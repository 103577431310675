import { mainRoutes, generateAbsoluteRoutes } from '../main/routes';

export const authRoutesRelative = {
  404: '/*',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  EMAIL_CONFIRMATION_CODE: '/sign-up/email-code',
  VERIFICATION_BY_URL: '/sign-up/verification-by-url/:token',
  RESET_PASSWORD: '/password-reset/:token',
  RESET_PASSWORD_EMAIL: '/password-reset-request',
};

export const authRoutes = generateAbsoluteRoutes(authRoutesRelative, mainRoutes.AUTH);
