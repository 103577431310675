import React, { memo } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { mainRoutes } from '../routes';
import { PageNotFound, ContactAdministrator } from 'features/error-pages';
import { Layout } from 'features/layout';
import { Discover } from 'features/discover';
import { Users } from 'features/mobile-users';
import { Support } from 'features/support';
import { EmailVerificationByUrl } from 'features/auth';
import { AdminUsers } from 'features/admin-users';
import { UserManagement } from 'features/user-management';
import { LogsV1 } from 'features/logs_v1';
import { redirectLinkAfterLogin } from 'features/hoc';
import { SupportV1 } from 'features/support_v1';
import { SupportQrios } from 'features/support_qrios';
import { Logs } from 'features/logs';

function ProtectedRoutes() {
  const role = useSelector((state) => state.authorization.flom.role);

  return (
    <Layout>
      <Routes>
        <Route path={mainRoutes.USER_MANAGEMENT + '/*'} element={<UserManagement />} />

        <Route path={mainRoutes.DISCOVER + '/*'} element={<Discover />} />

        <Route path={mainRoutes.SUPPORT + '/*'} element={<Support />} />

        <Route path={mainRoutes.MOBILE_USERS + '/*'} element={<Users />} />

        <Route path={mainRoutes.ADMIN_USERS + '/*'} element={<AdminUsers />} />

        <Route path={mainRoutes.LOGS + '/*'} element={<Logs />} />

        <Route path={mainRoutes.LOGS_V1 + '/*'} element={<LogsV1 />} />

        <Route
          path={mainRoutes.CONTACT_ADMINISTRATOR}
          element={<ContactAdministrator type="all" />}
        />

        <Route path={mainRoutes.AUTH} element={<EmailVerificationByUrl />} />

        <Route path={mainRoutes.SUPPORT_V1 + '/*'} element={<SupportV1 />} />

        <Route path={mainRoutes.SUPPORT_QRIOS + '/*'} element={<SupportQrios />} />

        <Route path={mainRoutes.MAIN} element={<Navigate to={redirectLinkAfterLogin(role)} />} />

        <Route path={mainRoutes[404]} element={<PageNotFound />} />
      </Routes>
    </Layout>
  );
}

export default memo(ProtectedRoutes);
