export function checkBrowser(userAgent = window.navigator.userAgent) {
  if ((userAgent.indexOf('Opera') || userAgent.indexOf('OPR')) !== -1) {
    return 'Opera';
  } else if (userAgent.indexOf('Chrome') !== -1) {
    return 'Chrome';
  } else if (userAgent.indexOf('Safari') !== -1) {
    return 'Safari';
  } else if (userAgent.indexOf('Firefox') !== -1) {
    return 'Firefox';
  } else if (userAgent.indexOf('MSIE') !== -1) {
    //IF IE > 10
    return 'IE';
  } else {
    return userAgent;
  }
}
