import React, { Suspense, lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router';
import { ErrorBoundary } from '../../errorBoundaries';
import {
  discoverRoutes,
  discoverRoutesRelative,
  breadcrumbLabels,
  breadcrumbLinks,
} from '../routes';
import { mainRoutes } from '../../main';
import { SetNavigation } from '../../layout';
import { LoadingFlom } from '../../components';
import { CheckRole } from 'features/hoc';
import { discoverRoleRules } from '../hooks';

// lazyImports
const Products = lazy(() => import('../products/fragments/products/Products'));
const ProductDetails = lazy(() => import('../products/fragments/productDetails/ProductDetails'));

const Videos = lazy(() => import('../videos/fragments/videos/Videos'));
const VideoDetails = lazy(() => import('../videos/fragments/videoDetails/VideoDetails'));

const Podcasts = lazy(() => import('../podcasts/fragments/podcasts/Podcasts'));
const PodcastDetails = lazy(() => import('../podcasts/fragments/podcastDetails/PodcastDetails'));

const TextStories = lazy(() => import('../textStories/fragments/textStories/TextStories'));
const TextStoryDetails = lazy(() =>
  import('../textStories/fragments/textStoryDetails/TextStoryDetails')
);

const Expos = lazy(() => import('../expos/fragments/Expos/Expos'));
const ExpoDetails = lazy(() => import('../expos/fragments/ExpoDetails/ExpoDetails'));

const PageNotFound = lazy(() => import('../../error-pages/fragments/pageNotFound/PageNotFound'));

const CategoryManagement = lazy(() =>
  import('../categoryManagement/fragments/categoryManagement/CategoryManagement')
);

const ProductDetailsNavigation = lazy(() =>
  import('../productDetailsNavigation/fragments/ProductDetailsNavigation')
);

function Discover() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingFlom />}>
        <Routes>
          <Route
            path={discoverRoutesRelative.PRODUCTS_DETAILS}
            element={
              <CheckRole {...discoverRoleRules.menu}>
                <ProductDetails />
              </CheckRole>
            }
          />

          <Route
            path={discoverRoutesRelative.PRODUCTS}
            element={
              <CheckRole {...discoverRoleRules.menu}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.products}
                  breadcrumbsLabels={breadcrumbLabels.products}
                  siderOpenedKey={mainRoutes.DISCOVER}
                  siderSelectedKey={discoverRoutes.PRODUCTS}
                >
                  <Products />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={discoverRoutesRelative.VIDEOS_DETAILS}
            element={
              <CheckRole {...discoverRoleRules.menu}>
                <VideoDetails />
              </CheckRole>
            }
          />
          <Route
            path={discoverRoutesRelative.VIDEOS}
            element={
              <CheckRole {...discoverRoleRules.menu}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.videos}
                  breadcrumbsLabels={breadcrumbLabels.videos}
                  siderOpenedKey={mainRoutes.DISCOVER}
                  siderSelectedKey={discoverRoutes.VIDEOS}
                >
                  <Videos />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={discoverRoutesRelative.PODCASTS_DETAILS}
            element={
              <CheckRole {...discoverRoleRules.menu}>
                <PodcastDetails />
              </CheckRole>
            }
          />
          <Route
            path={discoverRoutesRelative.PODCASTS}
            element={
              <CheckRole {...discoverRoleRules.menu}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.podcasts}
                  breadcrumbsLabels={breadcrumbLabels.podcasts}
                  siderOpenedKey={mainRoutes.DISCOVER}
                  siderSelectedKey={discoverRoutes.PODCASTS}
                >
                  <Podcasts />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={discoverRoutesRelative.TEXT_STORIES_DETAILS}
            element={
              <CheckRole {...discoverRoleRules.menu}>
                <TextStoryDetails />
              </CheckRole>
            }
          />
          <Route
            path={discoverRoutesRelative.TEXT_STORIES}
            element={
              <CheckRole {...discoverRoleRules.menu}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.textStories}
                  breadcrumbsLabels={breadcrumbLabels.textStories}
                  siderOpenedKey={mainRoutes.DISCOVER}
                  siderSelectedKey={discoverRoutes.TEXT_STORIES}
                >
                  <TextStories />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={discoverRoutesRelative.EXPOS_DETAILS}
            element={
              <CheckRole {...discoverRoleRules.menu}>
                <ExpoDetails />
              </CheckRole>
            }
          />
          <Route
            path={discoverRoutesRelative.EXPOS}
            element={
              <CheckRole {...discoverRoleRules.menu}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.expos}
                  breadcrumbsLabels={breadcrumbLabels.expos}
                  siderOpenedKey={mainRoutes.DISCOVER}
                  siderSelectedKey={discoverRoutes.EXPOS}
                >
                  <Expos />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={discoverRoutesRelative.CATEGORY_MANAGEMENT}
            element={
              <CheckRole {...discoverRoleRules.productCategoryManagement}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.categoryManagement}
                  breadcrumbsLabels={breadcrumbLabels.categoryManagement}
                  siderOpenedKey={mainRoutes.DISCOVER}
                  siderSelectedKey={discoverRoutes.CATEGORY_MANAGEMENT}
                >
                  <CategoryManagement />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={discoverRoutesRelative.DETAILS}
            element={
              <CheckRole {...discoverRoleRules.menu}>
                <SetNavigation>
                  <ProductDetailsNavigation />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route path="/" element={<Navigate to={discoverRoutes.PRODUCTS} />} />

          <Route path={discoverRoutesRelative[404]} element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}

export default Discover;
