import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import styles from './ContactAdministrator.module.scss';
import { SetNavigation } from '../../../layout';

function ContactAdministrator({ type }) {
  const messages = (type) => {
    switch (type) {
      case 'all':
        return `Your account doesn't have access to any information. Please contact your administrator to get access rights.`;
      default:
        return `Your account doesn't have access to this page. Please contact your administrator to get access rights.`;
    }
  };
  return (
    <SetNavigation>
      <div className={styles.mainContainer}>
        <Typography.Text className={styles.text}>{messages(type)}</Typography.Text>
      </div>
    </SetNavigation>
  );
}

ContactAdministrator.propTypes = {
  type: PropTypes.oneOf(['all']),
};

// ContactAdministrator.defaultProps = {};

export default ContactAdministrator;
