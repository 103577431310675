import { SettingFilled } from '@ant-design/icons';
import { mainLabels, mainRoutes } from 'features/main/routes';
import { supportQriosLabels, supportQriosRoutes } from './routes';
import { useShowSupportQrios } from './hooks';

export const useSupportQriosSider = () => {
  const showSupportQrios = useShowSupportQrios();

  if (showSupportQrios.menu) {
    return [
      showSupportQrios.letsTalk && {
        // type: 'subMenu',
        label: mainLabels[mainRoutes.SUPPORT_QRIOS],
        key: mainRoutes.SUPPORT_QRIOS,
        icon: <SettingFilled />,
        children: [
          {
            label: supportQriosLabels[supportQriosRoutes.LETS_TALK],
            key: supportQriosRoutes.LETS_TALK,
          },
        ],
      },
    ];
  }
  return [];
};
