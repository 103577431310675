export const mergeArrays = (array1, array2) => {
  const hash = {};
  const ret = [];
  let i;

  for (i = 0; i < array1.length; i++) {
    const element = array1[i];
    if (!hash[element]) {
      hash[element] = true;
      ret.push(element);
    }
  }
  for (i = 0; i < array2.length; i++) {
    const element = array2[i];
    if (!hash[element]) {
      hash[element] = true;
      ret.push(element);
    }
  }

  return ret;
};

export const booleanToNumber = (object) => {
  const returnObject = { ...object };
  const keys = Object.keys(returnObject);
  keys.map((key) => {
    if (typeof returnObject[key] === 'boolean') {
      returnObject[key] = returnObject[key] ? 1 : 0;
    }
    return null;
  });
  return returnObject;
};

export const isValidHttpUrl = (url) => {
  try {
    new URL(url);
  } catch (error) {
    return false;
  }
  return true;
};

export const handleErrorMessage = (errorMessage) => {
  if (errorMessage) {
    const message = JSON.parse(errorMessage);
    return message.errorMessage;
  } else {
    return 'Something went wrong, please try again later.';
  }
};
