import { TeamOutlined } from '@ant-design/icons';
import { mainLabels, mainRoutes } from 'features/main/routes';
import { adminUsersLabels, adminUsersRoutes } from './routes';
import { useShowAdminUsers } from './hooks';

export const useAdminUsersSider = () => {
  const showAdminUsers = useShowAdminUsers();

  if (showAdminUsers.menu) {
    return [
      {
        // type: 'subMenu',
        label: mainLabels[mainRoutes.ADMIN_USERS],
        key: mainRoutes.ADMIN_USERS,
        icon: <TeamOutlined />,
        children: [
          showAdminUsers.users && {
            label: adminUsersLabels[adminUsersRoutes.USERS],
            key: adminUsersRoutes.USERS,
          },
          showAdminUsers.createUser && {
            label: adminUsersLabels[adminUsersRoutes.CREATE_USER],
            key: adminUsersRoutes.CREATE_USER,
          },
        ],
      },
    ];
  }

  return [];
};
