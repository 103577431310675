import { useCheckRole } from 'features/hoc';
import { adminUsersRoleRules } from './adminUsersRoleRules';

export function useShowAdminUsers() {
  const showAdminUsers = {
    menu: useCheckRole(adminUsersRoleRules.menu),
    users: useCheckRole(adminUsersRoleRules.users),
    createUser: useCheckRole(adminUsersRoleRules.createUser),
  };

  return showAdminUsers;
}
