import { useCheckRole } from 'features/hoc';
import { logsRoleRules } from './logsRoleRules';

export function useShowLogs() {
  const showLogs = {
    menu: useCheckRole(logsRoleRules.menu),
    discover: useCheckRole(logsRoleRules.discover),
    moderators: useCheckRole(logsRoleRules.moderators),
    payment: useCheckRole(logsRoleRules.payment),
  };

  return showLogs;
}
