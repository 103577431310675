import React from 'react';
import { Button, Row, Col, Input, Form, message } from 'antd';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { signUpApi } from 'features/auth';

function NewEmail({ onCancel, onFinish, id }) {
  const [form] = Form.useForm();

  const changeVerificationEmail = useMutation(
    ({ email }) => signUpApi.changeVerificationEmailApi(email, id),
    {
      onMutate: () => {
        message.loading({
          content: 'Changing verification email.',
          key: 'changeVerificationEmail',
          duration: 100,
        });
      },
      onSuccess: (response, variables) => {
        message.success({
          content: `Email has been send to: ${variables.email}`,
          key: 'changeVerificationEmail',
          duration: 4,
        });

        onFinish(variables.email);
      },
      onError: () => {
        message.error({
          content: 'Changing verification email failed.',
          key: 'changeVerificationEmail',
          duration: 4,
        });
      },
    }
  );

  const onFinishHandler = () => {
    changeVerificationEmail.mutate({ email: form.getFieldValue('email') });
  };

  return (
    <>
      <Form layout="vertical" onFinish={onFinishHandler} form={form}>
        <Form.Item
          label="New email:"
          name="email"
          validateTrigger="onSubmit"
          rules={[
            { required: true, message: '*email is required field' },
            {
              validator: (_, value) => {
                const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i;

                if (!value) {
                  return Promise.resolve();
                }
                if (regex.test(String(value).toLowerCase())) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(`The format of the email address isn't correct.`));
              },
            },
          ]}
        >
          <Input autoComplete="email" name="email" type="email" />
        </Form.Item>
      </Form>

      <Row justify="end" style={{ marginTop: 16 }}>
        <Col>
          <Button onClick={onCancel} disabled={changeVerificationEmail.isLoading}>
            Cancel
          </Button>
        </Col>
        <Col style={{ marginLeft: 8 }}>
          <Button onClick={form.submit} type="primary" loading={changeVerificationEmail.isLoading}>
            Submit
          </Button>
        </Col>
      </Row>
    </>
  );
}

NewEmail.propTypes = {
  onCancel: PropTypes.func,
  onFinish: PropTypes.func,

  closeModal: PropTypes.func,
  id: PropTypes.string,
  changeEmail: PropTypes.func,
};

NewEmail.defaultProps = {
  onCancel: () => {},
  onFinish: () => {},

  closeModal: () => {},
  changeEmail: () => {},
};

export default NewEmail;
