import axios from 'axios';
import { store } from '../main';

export const flomV2 = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 30000,
});

flomV2.interceptors.request.use((config) => {
  const token = store.getState().authorization.flom.accessToken;
  if (token) {
    config.headers['access-token'] = token;
  }

  return config;
});

export const createFileUrlV2 = (nameOnServer) => {
  if (typeof nameOnServer === 'string' && nameOnServer.length > 0) {
    return process.env.REACT_APP_BASE_URL + '/api/v2/product/file/' + nameOnServer;
  }
  return null;
};
