export const handleFileDimensions = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = () => resolve({ width: image.width, height: image.height });
      image.onerror = reject;
    };
  });
};
