import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Typography, Spin } from 'antd';
import PropTypes from 'prop-types';

function ResendCounter({ onResend, countDownTime, loading }) {
  const [counter, setCounter] = useState(countDownTime);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    if (loading === false) {
      setCounter(countDownTime);
    }
  }, [loading]);

  const onResendHandler = () => {
    setCounter(countDownTime);
    onResend();
  };

  return (
    <Form.Item>
      <Row align="middle" justify="center">
        <Col>
          {!loading && counter === 0 && (
            <Typography.Text>
              Didn't get the code?{' '}
              <Typography.Link onClick={onResendHandler}>RESEND</Typography.Link>
            </Typography.Text>
          )}
          {!loading && counter > 0 && (
            <Typography.Text strong type="success">
              {counter}
            </Typography.Text>
          )}
          {loading && <Spin />}
        </Col>
      </Row>
    </Form.Item>
  );
}

ResendCounter.propTypes = {
  onResend: PropTypes.func,
  countDownTime: PropTypes.number,
  loading: PropTypes.bool,
};

ResendCounter.defaultProps = {
  onResend: () => {},
  countDownTime: 60,
  loading: false,
};

export default ResendCounter;
