import React, { memo } from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';
import { transactionTypes } from 'features/support/transactions/const';

function TransactionType({ type }) {
  switch (type) {
    case transactionTypes.topUp.value:
      return <Tag color="purple">{transactionTypes.topUp.name}</Tag>;
    case transactionTypes.data.value:
      return <Tag color="geekblue">{transactionTypes.data.name}</Tag>;
    case transactionTypes.bless.value:
      return <Tag color="blue">{transactionTypes.bless.name}</Tag>;
    case transactionTypes.marketplace.value:
      return <Tag color="cyan">{transactionTypes.marketplace.name}</Tag>;
    case transactionTypes.membership.value:
      return <Tag color="lime">{transactionTypes.membership.name}</Tag>;
    case transactionTypes.cash.value:
      return <Tag color="gold">{transactionTypes.cash.name}</Tag>;
    case transactionTypes.creditPackage.value:
      return <Tag color="magenta">{transactionTypes.creditPackage.name}</Tag>;
    case transactionTypes.creditTransfer.value:
      return <Tag color="volcano">{transactionTypes.creditTransfer.name}</Tag>;
    case transactionTypes.sprayBless.value:
      return <Tag color="orange">{transactionTypes.sprayBless.name}</Tag>;
    case transactionTypes.sats.value:
      return <Tag color="volcano">{transactionTypes.sats.name}</Tag>;
    case transactionTypes.satsPurchase.value:
      return <Tag color="#EBB8DD">{transactionTypes.satsPurchase.name}</Tag>;
    case transactionTypes.bonusDataPackage.value:
      return <Tag color="red">{transactionTypes.bonusDataPackage.name}</Tag>;
    case transactionTypes.payout.value:
      return <Tag color="red">{transactionTypes.payout.name}</Tag>;
    default:
      return <Tag color="default">Unknown</Tag>;
  }
}

TransactionType.propTypes = {
  type: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 98, 99]),
};

export default memo(TransactionType);
