import React, { Suspense, lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router';
import { ErrorBoundary } from '../../errorBoundaries';
import {
  breadcrumbLabels,
  supportV1Routes,
  breadcrumbLinks,
  supportV1RoutesRelative,
} from '../routes';
import { mainRoutes } from '../../main';
import { SetNavigation } from '../../layout';
import { LoadingFlom } from '../../components';
import { CheckRole } from 'features/hoc';
import { supportV1RoleRules } from '../hooks';

// lazyImports
const PageNotFound = lazy(() => import('../../error-pages/fragments/pageNotFound/PageNotFound'));
const Transactions = lazy(() => import('../transactions/fragments/transactions/Transactions'));
const TransactionDetails = lazy(() =>
  import('../transactions/fragments/transactionDetails/TransactionDetails')
);
const SupportTicketsV1 = lazy(() =>
  import('../supportTickets/fragments/supportTickets/SupportTickets')
);
const SupportTicketsDetails = lazy(() =>
  import('../supportTickets/fragments/supportTicketsDetails/SupportTicketsDetails')
);
const Flomoji = lazy(() => import('../flomoji/fragments/flomoji/Flomoji'));
const Sounds = lazy(() => import('../sounds/fragments/sounds/Sounds'));
const SoundDetails = lazy(() => import('../sounds/fragments/soundDetails/SoundDetails'));
const SMSData = lazy(() => import('../SMSData/fragments/smsData/SMSData'));
const SMSDataDetails = lazy(() => import('../SMSData/fragments/smsDataDetails/SMSDataDetails'));
const BlockedThirdPartyProducts = lazy(() =>
  import('../blockedThirdPartyProducts/fragments/BlockedThirdPartyProducts')
);
const CustomerActivation = lazy(() =>
  import('../customerActivation/fragments/customeractivation/CustomerActivation')
);
const Taxes = lazy(() => import('../taxes/fragments/taxes/Taxes'));
const ChatGPT = lazy(() => import('../chatGPT/fragments/chatGPT/ChatGPT'));
const IDApplication = lazy(() => import('../IDApplication/fragments/IDApplication/IDApplication'));
const IDApplicationDetails = lazy(() =>
  import('../IDApplication/fragments/IDApplicationDetails/IDApplicationDetails')
);
const Sats = lazy(() => import('../sats/fragments/sats/Sats'));
const SendSMS = lazy(() => import('../sendSMS/fragments/sendsms/SendSMS'));
const ContactUs = lazy(() => import('../contactUs/fragments/contactUs/ContactUs'));
const ContactUsDetails = lazy(() =>
  import('../contactUs/fragments/contactUsDetails/ContactUsDetails')
);
const Credits = lazy(() => import('../credits/fragments/Credits'));
const BalanceEmoji = lazy(() => import('../credits/fragments/BalanceEmoji'));
const SprayAmount = lazy(() => import('../credits/fragments/SprayAmount'));
const ConversionRates = lazy(() => import('../credits/fragments/ConversionRates'));
const CreditLimits = lazy(() => import('../credits/fragments/CreditLimits'));

const Payout = lazy(() => import('../payout/fragments/payout/Payout'));
const PayoutDetails = lazy(() => import('../payout/fragments/payoutDetails/PayoutDetails'));
const PayoutLimits = lazy(() => import('../payout/fragments/payoutLimits/PayoutLimits'));
const AddCredits = lazy(() => import('../credits/fragments/AddCredits'));
const Fees = lazy(() => import('../fees/fragments/Fees'));

const EmojiSets = lazy(() => import('../emojiSets/fragments/EmojiSets'));
const EmojiItems = lazy(() => import('../emojiSets/fragments/EmojiItems'));

const SettingsV1 = lazy(() => import('../settings/fragments/settings/SettingsV1'));

function Support() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingFlom />}>
        <Routes>
          <Route
            path={supportV1RoutesRelative.TRANSACTIONS_DETAILS}
            element={
              <CheckRole {...supportV1RoleRules.transactions}>
                <TransactionDetails />
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.TRANSACTIONS}
            element={
              <CheckRole {...supportV1RoleRules.transactions}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.transactions}
                  breadcrumbsLabels={breadcrumbLabels.transactions}
                  siderOpenedKey={mainRoutes.SUPPORT_V1}
                  siderSelectedKey={supportV1Routes.TRANSACTIONS}
                >
                  <Transactions />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.SUPPORT_TICKETS_DETAILS}
            element={
              <CheckRole {...supportV1RoleRules.supportTickets}>
                <SupportTicketsDetails />
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.SUPPORT_TICKETS}
            element={
              <CheckRole {...supportV1RoleRules.supportTickets}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.supportTickets}
                  breadcrumbsLabels={breadcrumbLabels.supportTickets}
                  siderOpenedKey={mainRoutes.SUPPORT_V1}
                  siderSelectedKey={supportV1Routes.SUPPORT_TICKETS}
                >
                  <SupportTicketsV1 />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.FLOMOJI}
            element={
              <CheckRole {...supportV1RoleRules.flomoji}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.flomoji}
                  breadcrumbsLabels={breadcrumbLabels.flomoji}
                  siderOpenedKey={mainRoutes.SUPPORT_V1}
                  siderSelectedKey={supportV1Routes.FLOMOJI}
                >
                  <Flomoji />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.SOUNDS}
            element={
              <CheckRole {...supportV1RoleRules.sounds}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.sounds}
                  breadcrumbsLabels={breadcrumbLabels.sounds}
                  siderOpenedKey={mainRoutes.SUPPORT_V1}
                  siderSelectedKey={supportV1Routes.SOUNDS}
                >
                  <Sounds />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.SOUNDS_DETAILS}
            element={
              <CheckRole {...supportV1RoleRules.sounds}>
                <SoundDetails />
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.SMSDATA}
            element={
              <CheckRole {...supportV1RoleRules.smsData}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.smsData}
                  breadcrumbsLabels={breadcrumbLabels.smsData}
                  siderOpenedKey={mainRoutes.SUPPORT_V1}
                  siderSelectedKey={supportV1Routes.SMSDATA}
                >
                  <SMSData />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.SMSDATA_DETAILS}
            element={
              <CheckRole {...supportV1RoleRules.smsData}>
                <SMSDataDetails />
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.BLOCKED_THIRD_PARTY_PRODUCTS}
            element={
              <CheckRole {...supportV1RoleRules.blockedThirdPartyProducts}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.blockedThirdPartyProducts}
                  breadcrumbsLabels={breadcrumbLabels.blockedThirdPartyProducts}
                  siderOpenedKey={mainRoutes.SUPPORT_V1}
                  siderSelectedKey={supportV1Routes.BLOCKED_THIRD_PARTY_PRODUCTS}
                >
                  <BlockedThirdPartyProducts />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.CUSTOMER_ACTIVATION}
            element={
              <CheckRole {...supportV1RoleRules.customerActivation}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.customerActivation}
                  breadcrumbsLabels={breadcrumbLabels.customerActivation}
                  siderOpenedKey={mainRoutes.SUPPORT_V1}
                  siderSelectedKey={supportV1Routes.CUSTOMER_ACTIVATION}
                >
                  <CustomerActivation />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.TAXES}
            element={
              <CheckRole {...supportV1RoleRules.taxes}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.taxes}
                  breadcrumbsLabels={breadcrumbLabels.taxes}
                  siderOpenedKey={mainRoutes.SUPPORT_V1}
                  siderSelectedKey={supportV1Routes.TAXES}
                >
                  <Taxes />
                </SetNavigation>
              </CheckRole>
            }
          />
          <Route
            path={supportV1RoutesRelative.BANNED_CHATGPT_COUNTRIES}
            element={
              <CheckRole {...supportV1RoleRules.chatGPT}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.chatGPT}
                  breadcrumbsLabels={breadcrumbLabels.chatGPT}
                  siderOpenedKey={mainRoutes.SUPPORT_V1}
                  siderSelectedKey={supportV1Routes.BANNED_CHATGPT_COUNTRIES}
                >
                  <ChatGPT />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.ID_APPLICATION}
            element={
              <CheckRole {...supportV1RoleRules.IDApplication}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.IDApplication}
                  breadcrumbsLabels={breadcrumbLabels.IDApplication}
                  siderOpenedKey={mainRoutes.SUPPORT_V1}
                  siderSelectedKey={supportV1Routes.ID_APPLICATION}
                >
                  <IDApplication />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.ID_APPLICATION_DETAILS}
            element={
              <CheckRole {...supportV1RoleRules.IDApplication}>
                <IDApplicationDetails />
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.SATS_BANNED_COUNTRIES}
            element={
              <CheckRole {...supportV1RoleRules.sats}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.sats}
                  breadcrumbsLabels={breadcrumbLabels.sats}
                  siderOpenedKey={mainRoutes.SUPPORT_V1}
                  siderSelectedKey={supportV1Routes.SATS_BANNED_COUNTRIES}
                >
                  <Sats />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.SEND_SMS}
            element={
              <CheckRole {...supportV1RoleRules.sendSMS}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.sendSMS}
                  breadcrumbsLabels={breadcrumbLabels.sendSMS}
                  siderOpenedKey={mainRoutes.SUPPORT_V1}
                  siderSelectedKey={supportV1Routes.SEND_SMS}
                >
                  <SendSMS />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.CONTACT_US}
            element={
              <CheckRole {...supportV1RoleRules.contactUs}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.contactUs}
                  breadcrumbsLabels={breadcrumbLabels.contactUs}
                  siderOpenedKey={mainRoutes.SUPPORT_V1}
                  siderSelectedKey={supportV1Routes.CONTACT_US}
                >
                  <ContactUs />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.CONTACT_US_DETAILS}
            element={
              <CheckRole {...supportV1RoleRules.contactUs}>
                <ContactUsDetails />
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.CREDITS}
            element={
              <CheckRole {...supportV1RoleRules.credits}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.credits}
                  breadcrumbsLabels={breadcrumbLabels.credits}
                  siderOpenedKey={mainRoutes.SUPPORT_V1}
                  siderSelectedKey={supportV1Routes.CREDITS}
                >
                  <Credits />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.CREDITS_BALANCE_EMOJI}
            element={
              <CheckRole {...supportV1RoleRules.balanceEmoji}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.balanceEmoji}
                  breadcrumbsLabels={breadcrumbLabels.balanceEmoji}
                  siderOpenedKey={mainRoutes.SUPPORT_V1}
                  siderSelectedKey={supportV1Routes.CREDITS_BALANCE_EMOJI}
                >
                  <BalanceEmoji />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.SPRAY_AMOUNT}
            element={
              <CheckRole {...supportV1RoleRules.sprayAmount}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.sprayAmount}
                  breadcrumbsLabels={breadcrumbLabels.sprayAmount}
                  siderOpenedKey={mainRoutes.SUPPORT_V1}
                  siderSelectedKey={supportV1Routes.SPRAY_AMOUNT}
                >
                  <SprayAmount />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.CONVERSION_RATES}
            element={
              <CheckRole {...supportV1RoleRules.conversionRates}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.conversionRates}
                  breadcrumbsLabels={breadcrumbLabels.conversionRates}
                  siderOpenedKey={mainRoutes.SUPPORT_V1}
                  siderSelectedKey={supportV1Routes.CONVERSION_RATES}
                >
                  <ConversionRates />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.CREDIT_LIMITS}
            element={
              <CheckRole {...supportV1RoleRules.creditLimits}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.creditLimits}
                  breadcrumbsLabels={breadcrumbLabels.creditLimits}
                  siderOpenedKey={mainRoutes.SUPPORT_V1}
                  siderSelectedKey={supportV1Routes.CREDIT_LIMITS}
                >
                  <CreditLimits />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.PAYOUT}
            element={
              <CheckRole {...supportV1RoleRules.payout}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.payout}
                  breadcrumbsLabels={breadcrumbLabels.payout}
                  siderOpenedKey={mainRoutes.SUPPORT_V1}
                  siderSelectedKey={supportV1Routes.PAYOUT}
                >
                  <Payout />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.PAYOUT_DETAILS}
            element={
              <CheckRole {...supportV1RoleRules.payout}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.payout}
                  breadcrumbsLabels={breadcrumbLabels.payout}
                  siderOpenedKey={mainRoutes.SUPPORT_V1}
                  siderSelectedKey={supportV1Routes.PAYOUT}
                >
                  <PayoutDetails />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.PAYOUT_LIMITS}
            element={
              <CheckRole {...supportV1RoleRules.payoutLimits}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.payoutLimits}
                  breadcrumbsLabels={breadcrumbLabels.payoutLimits}
                  siderOpenedKey={mainRoutes.SUPPORT_V1}
                  siderSelectedKey={supportV1Routes.PAYOUT_LIMITS}
                >
                  <PayoutLimits />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.ADD_CREDITS}
            element={
              <CheckRole {...supportV1RoleRules.addCredits}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.addCredits}
                  breadcrumbsLabels={breadcrumbLabels.addCredits}
                  siderOpenedKey={mainRoutes.SUPPORT_V1}
                  siderSelectedKey={supportV1Routes.ADD_CREDITS}
                >
                  <AddCredits />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.FEES}
            element={
              <CheckRole {...supportV1RoleRules.fees}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.fees}
                  breadcrumbsLabels={breadcrumbLabels.fees}
                  siderOpenedKey={mainRoutes.SUPPORT_V1}
                  siderSelectedKey={supportV1Routes.FEES}
                >
                  <Fees />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.STICKER_SETS}
            element={
              <CheckRole {...supportV1RoleRules.stickerSets}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.stickerSets}
                  breadcrumbsLabels={breadcrumbLabels.stickerSets}
                  siderOpenedKey={mainRoutes.SUPPORT_V1}
                  siderSelectedKey={supportV1Routes.STICKER_SETS}
                >
                  <EmojiSets />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.STICKER_ITEMS}
            element={
              <CheckRole {...supportV1RoleRules.stickerSets}>
                <EmojiItems />
              </CheckRole>
            }
          />

          <Route
            path={supportV1RoutesRelative.SETTINGS}
            element={
              <CheckRole {...supportV1RoleRules.settings}>
                <SetNavigation
                  breadcrumbsLinks={breadcrumbLinks.settings}
                  breadcrumbsLabels={breadcrumbLabels.settings}
                  siderOpenedKey={mainRoutes.SUPPORT_V1}
                  siderSelectedKey={supportV1Routes.settings}
                >
                  <SettingsV1 />
                </SetNavigation>
              </CheckRole>
            }
          />

          <Route path="/" element={<Navigate to={supportV1Routes.TRANSACTIONS} />} />

          <Route path={supportV1RoutesRelative[404]} element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}

export default Support;
