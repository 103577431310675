import React, { useEffect } from 'react';
import { Select, Form, Button, Input } from 'antd';
import PropTypes from 'prop-types';
import { useParametersFromLocationSearch } from 'core/hooks';
import styles from './ProductFilter.module.scss';
import './antdOverride.scss';
import { useCheckRole, roles } from 'features/hoc';
import { status } from '../../const';
import { countries } from 'core/helpers';
import { UsernameInput } from 'features/components';

function ProductFilter({ onFilterChangeHandler, isLoading, productTitle }) {
  const [form] = Form.useForm();
  const searchParams = useParametersFromLocationSearch();

  useEffect(() => {
    if (searchParams) {
      form.resetFields();
      form.setFieldsValue(searchParams);
    }
  }, [searchParams, form]);

  const onSubmitHandler = () => {
    const filter = form.getFieldsValue();
    const filterKeys = Object.keys(filter);
    let filteredFilter = {};
    filterKeys.map((filterKey) => {
      if (filter[filterKey]) {
        filteredFilter[filterKey] = filter[filterKey];
      }
      return null;
    });

    onFilterChangeHandler(filteredFilter);
  };

  const onClearHandler = () => {
    form.resetFields();
    onFilterChangeHandler();
  };

  return (
    <div className="override-antd">
      <Form name="filter" form={form} onFinish={onSubmitHandler} className={styles.container}>
        <Form.Item name="moderationStatus" label="Status" initialValue={0}>
          <Select>
            <Select.Option value={`${status.pending.code}`}>{status.pending.name}</Select.Option>
            <Select.Option value={`${status.approved.code}`}>{status.approved.name}</Select.Option>
            <Select.Option value={`${status.declined.code}`}>{status.declined.name}</Select.Option>
            {useCheckRole({ min: roles.approvers.value }) && (
              <Select.Option value={`${status.approvalNeeded.code}`}>
                {status.approvalNeeded.name}
              </Select.Option>
            )}
            <Select.Option value={0}>All</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Username" name="username">
          <UsernameInput placeholder="" autoComplete="username-filter" />
        </Form.Item>

        <Form.Item label={productTitle} name="productName">
          <Input autoComplete="productName" autoCapitalize="off" />
        </Form.Item>

        <Form.Item label="Country" name="countryCode">
          <Select
            showSearch
            filterOption={(inputValue, option) => {
              return option.name?.toLowerCase().includes(inputValue.toLowerCase());
            }}
          >
            <Select.Option name="all" value={''}>
              All
            </Select.Option>
            {countries.map((country) => {
              return (
                <Select.Option key={country.code} name={country.name} value={country.code}>
                  {country.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <div className={styles.buttonContainer}>
          <Form.Item className={styles.filterButton}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Filter
            </Button>
          </Form.Item>

          <Form.Item className={styles.clearButton}>
            <Button type="dashed" danger onClick={onClearHandler}>
              Clear
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}

ProductFilter.propTypes = {
  onFilterSubmitHandler: PropTypes.func,
  isLoading: PropTypes.bool,
  productTitle: PropTypes.oneOf([
    'Product title',
    'Video title',
    'Podcast title',
    'Story title',
    'Expo title',
  ]),
};

ProductFilter.defaultProps = {
  onFilterSubmitHandler: () => {},
  isLoading: false,
  productTitle: 'Product Title',
};

export default ProductFilter;
