import { SettingFilled } from '@ant-design/icons';
import { mainLabels, mainRoutes } from 'features/main/routes';
import { supportLabels, supportRoutes, supportSubMenu } from './routes';
import { useShowSupport } from './hooks';

export const useSupportV2Sider = () => {
  const showSupportV2 = useShowSupport();

  if (showSupportV2.menu) {
    return [
      {
        // type: 'subMenu',
        label: mainLabels[mainRoutes.SUPPORT],
        key: mainRoutes.SUPPORT,
        icon: <SettingFilled />,
        children: [
          showSupportV2.supportTickets && {
            label: supportLabels[supportRoutes.SUPPORT_TICKETS],
            key: supportRoutes.SUPPORT_TICKETS,
          },
          showSupportV2.transactions && {
            label: supportLabels[supportRoutes.TRANSACTIONS],
            key: supportRoutes.TRANSACTIONS,
          },
          showSupportV2.bannedIps && {
            label: supportLabels[supportRoutes.BANNED_IPS],
            key: supportRoutes.BANNED_IPS,
          },
          showSupportV2.bannedPhones && {
            label: supportLabels[supportRoutes.BANNED_PHONES],
            key: supportRoutes.BANNED_PHONES,
          },
          showSupportV2.transactionLimits && {
            label: supportLabels[supportRoutes.TRANSACTION_LIMITS],
            key: supportRoutes.TRANSACTION_LIMITS,
          },
          showSupportV2.pushNotifications && {
            label: supportLabels[supportRoutes.PUSH_NOTIFICATIONS],
            key: supportRoutes.PUSH_NOTIFICATIONS,
          },
          showSupportV2.settings && {
            label: supportLabels[supportRoutes.SETTINGS],
            key: supportRoutes.SETTINGS,
          },
          showSupportV2.conversionRates && {
            label: supportLabels[supportRoutes.CONVERSION_RATES],
            key: supportRoutes.CONVERSION_RATES,
          },
          showSupportV2.promotions && {
            label: supportLabels[supportRoutes.PROMOTIONS],
            key: supportSubMenu.promotions,
            children: [
              {
                label: supportLabels[supportRoutes.PROMOTIONS],
                key: supportRoutes.PROMOTIONS,
              },
              {
                label: supportLabels[supportRoutes.PROMOTION_SETTINGS],
                key: supportRoutes.PROMOTION_SETTINGS,
              },
              {
                label: supportLabels[supportRoutes.PROMOTION_CREATE],
                key: supportRoutes.PROMOTION_CREATE,
              },
            ],
          },
        ],
      },
    ];
  }

  return [];
};
