import React, { memo } from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';
import { roles, rolesApi } from 'features/hoc';

function RoleTag({ role }) {
  const statusToText = (role) => {
    switch (role) {
      case roles.superAdmin.value:
        return <Tag color="magenta">{roles.superAdmin.name}</Tag>;
      case roles.admin.value:
        return <Tag color="red">{roles.admin.name}</Tag>;
      case roles.contentManager.value:
        return <Tag color="lime">{roles.contentManager.name}</Tag>;
      case roles.approvers.value:
        return <Tag color="green">{roles.approvers.name}</Tag>;
      case roles.reviewers.value:
        return <Tag color="cyan">{roles.reviewers.name}</Tag>;
      case roles.supportTicket.value:
        return <Tag color="blue">{roles.supportTicket.name}</Tag>;
      case roles.verifiedEmail.value:
        return <Tag color="geekblue">{roles.verifiedEmail.name}</Tag>;
      case roles.notVerified.value:
        return <Tag color="purple">{roles.notVerified.name}</Tag>;
      default:
        return <Tag color="default">Unknown</Tag>;
    }
  };
  return statusToText(role);
}

RoleTag.propTypes = {
  role: PropTypes.oneOf(Object.values(rolesApi)),
};

export default memo(RoleTag);
