import React, { Suspense, lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router';

import { ErrorBoundary } from 'features/errorBoundaries';
import { LoadingFlom } from 'features/components';
import { SetNavigation } from 'features/layout';
import {
  userManagementRoutes,
  breadcrumbLabels,
  breadcrumbLinks,
  userManagementRoutesRelative,
} from '../../routes';

// lazyImports
const Settings = lazy(() => import('../../settings/fragments/settings/Settings'));
const PageNotFound = lazy(() => import('../../../error-pages/fragments/pageNotFound/PageNotFound'));

function UserManagement() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingFlom />}>
        <Routes>
          <Route
            path={userManagementRoutesRelative.SETTINGS}
            element={
              <SetNavigation
                breadcrumbsLinks={breadcrumbLinks.settings}
                breadcrumbsLabels={breadcrumbLabels.settings}
              >
                <Settings />
              </SetNavigation>
            }
          />

          <Route path="/" element={<Navigate to={userManagementRoutes.SETTINGS} />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}

export default UserManagement;
