import React from 'react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

import { store, persistor } from './configureStore';
import App from 'features';
import { theme } from './antdTheme';

import { checkBrowser } from 'core/helpers';

// Resolves DatePicker crash
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

//cache queries
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      refetchOnWindowFocus: false,
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

const isSafari = checkBrowser() === 'Safari';

function AppProvider() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
          <BrowserRouter>
            <ConfigProvider theme={theme}>
              {isSafari && (
                <Helmet>
                  <meta
                    name="viewport"
                    content="width=device-width, user-scalable=no, initial-scale=1"
                  />
                </Helmet>
              )}
              <App />
            </ConfigProvider>
          </BrowserRouter>
          {process.env.REACT_APP_MODE === 'development' && (
            <>
              <ReactQueryDevtools initialIsOpen={false} />
            </>
          )}
        </PersistQueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default AppProvider;
