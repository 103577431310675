import * as linkify from 'linkifyjs';
import { createFileUrlV1 } from 'core/api';
import { mentionHref } from './uniqueForAdmin';

const parseParagraphs = (paragraphs) => {
  try {
    return JSON.parse(paragraphs).paragraphs;
  } catch (error) {}
};

const addStringAtIndex = (string, stringToAdd, index) => {
  const beforeIndex = string.slice(0, index);
  const afterIndex = string.slice(index);
  return beforeIndex + stringToAdd + afterIndex;
};

const inlineStyleLink = (style) => {
  const linkDetails = linkify.find(style.linkTo)[0];
  if (linkDetails && linkDetails.href) {
    const href = `href="${linkDetails.href}" target="_blank" rel="noopener noreferrer"`;

    return { href, linkHtmlTag: 'a', linkStyle: { color: '#18c67f' } };
  }
  return {};
};

const inlineStyleMention = (style) => {
  return {
    href: mentionHref(style.userName),
    linkHtmlTag: 'a',
    linkStyle: { color: '#18c67f' },
  };
};

const addLinkToInlineStyle = (style) => {
  if (style.linkTo) {
    return inlineStyleLink(style);
  }
  if (style.userName) {
    return inlineStyleMention(style);
  }
  return {};
};

const styleObjectToString = (styleObject) => {
  let stringStyle = '';

  if (typeof styleObject === 'object' && Object.keys(styleObject).length > 0) {
    Object.keys(styleObject).forEach((key) => {
      if (styleObject[key] !== undefined) {
        stringStyle += `${key}: ${styleObject[key]}; `;
      }
    });
  }

  return stringStyle;
};

const createInlineStyle = (style, textStyleModifications = {}) => {
  const { fontColor, fontSize } = textStyleModifications;

  const textStyles = {
    color: fontColor || style.fontColor,
    // eslint-disable-next-line
    ['font-size']: fontSize || style.fontSize,
  };

  if (style.isBold) {
    textStyles['font-weight'] = 'bold';
  }
  if (style.isItalic) {
    textStyles['font-style'] = 'italic';
  }
  if (style.isUnderline) {
    textStyles['text-decoration'] = 'underline';
  }

  const { href, linkHtmlTag, linkStyle } = addLinkToInlineStyle(style);

  const textStylesString = styleObjectToString({ ...textStyles, ...linkStyle });

  const htmlTag = linkHtmlTag || 'span';
  return {
    prefix: `<${htmlTag} ${href || ''} style="${textStylesString}">`,
    sufix: `</${htmlTag}>`,
    start: style.start,
    end: style.start + style.length,
  };
};

const addInlineStyleToText = (paragraph, config) => {
  let text = paragraph.text;
  const styles = paragraph.styles;

  for (let i = styles.length - 1; i >= 0; i--) {
    const style = createInlineStyle(styles[i], config);
    text = addStringAtIndex(text, style.sufix, style.end);
    text = addStringAtIndex(text, style.prefix, style.start);
  }
  return text;
};

const createDivisionStyleForText = (paragraph, config = { overrideType: '' }) => {
  const { paragraphType, backColor, leftIndentLevel, textAlignment } = paragraph;
  const { overrideType, override } = config;
  const divisionType = overrideType || paragraphType;

  let divisionStyle = `white-space: pre-wrap; overflow-wrap: break-word; background-color: ${
    override.backColor || backColor
  }; text-indent: ${leftIndentLevel * 1.25}em; text-align: ${textAlignment};`;

  return {
    prefix: `<${divisionType} style="${divisionStyle}">`,
    sufix: `</${divisionType}>`,
  };
};

const createDivisionStyleForImage = (paragraph, config = { width: 414 }) => {
  const { backColor, imageFileModel, imageAspectRatio, imageScaleSize } = paragraph;
  const { width } = config;

  let backgroundElementStyle = `background-color: ${backColor}; padding-bottom: 1em`;
  let divisionStyle = `display: block; margin: auto; background-color: ${backColor};`;

  let src = createFileUrlV1(imageFileModel.file?.name);
  if (!imageFileModel.thumb) {
    src = process.env.REACT_APP_BASE_URL_OLD + '/api/v2/file/' + imageFileModel.file.id;
  }

  let imgWidth = width;
  if (imageScaleSize === 1) {
    imgWidth = width - 24 - 24;
  } else if (imageScaleSize === -1) {
    imgWidth = width;
  } else if (imageScaleSize < 1) {
    imgWidth = (width - 24 - 24) * imageScaleSize;
  }
  const height = imgWidth / imageAspectRatio;

  return {
    prefix: `<div style="${backgroundElementStyle}"><img src="${src}" width="${imgWidth}" height="${height}" style="${divisionStyle}">`,
    sufix: `</img></div>`,
  };
};

const createDivisionForSpace = (paragraph, config) => {
  const { fontColorForSpaceType, backColor } = paragraph;
  const { override } = config;
  const divisionStyle = `height: 100px; line-height: 100px; font-size:28px; background-color: ${
    override.backColor || backColor
  }; color: ${override.textColor || fontColorForSpaceType}; text-align: center;`;

  return `<div style="${divisionStyle}">• • •</div>`;
};

const createDivisionForVideo = (paragraph, config = { width: 414 }) => {
  const { videoFileModel, videoAspectRatio, backColor, videoScaleSize = 1 } = paragraph;
  const { width } = config;

  const backgroundElementStyle = `background-color: ${backColor}; padding-bottom: 1em`;
  let divisionStyle = `display: block; margin: auto; background-color: ${backColor};`;

  const src = createFileUrlV1(videoFileModel.file?.name);

  let videoWidth = width;
  if (videoScaleSize === 1) {
    videoWidth = width - 24 - 24;
  } else if (videoScaleSize === -1) {
    videoWidth = width;
  } else if (videoScaleSize < 1) {
    videoWidth = (width - 24 - 24) * videoScaleSize;
  }
  const height = videoWidth / videoAspectRatio;

  return {
    prefix: `<div style="${backgroundElementStyle}"><video src="${src}" autoplay muted="true" loop width="${videoWidth}" height="${height}" style="${divisionStyle}" controls>`,
    sufix: `</video></div>`,
  };
};

const createDivision = (paragraph, config) => {
  switch (paragraph.paragraphType) {
    case 'p':
    case 'h1':
    case 'h2':
    case 'h3': {
      const { prefix, sufix } = createDivisionStyleForText(paragraph, config);
      return prefix + addInlineStyleToText(paragraph, config) + sufix;
    }

    case 'ol':
    case 'ul': {
      const { prefix, sufix } = createDivisionStyleForText(paragraph, {
        ...config,
        overrideType: 'p',
      });
      return prefix + addInlineStyleToText(paragraph, config) + sufix;
    }

    case 'img': {
      const { prefix, sufix } = createDivisionStyleForImage(paragraph, config);
      return prefix + sufix;
    }

    case 'space': {
      return createDivisionForSpace(paragraph, config);
    }

    case 'video': {
      const { prefix, sufix } = createDivisionForVideo(paragraph, config);
      return prefix + sufix;
    }

    default:
      console.log('unknown paragraph type:', paragraph.paragraphType);
      return '';
  }
};

/**
 * @param {Object} config - Configuration object
 * @param {number} config.width - Width of rich text window
 * @param {Object} config.override - override object
 * @param {string} config.override.textColor - text color override
 * @param {string} config.override.backColor - paragraph background color override
 * @param {Object} config.override.img - image override
 * @param {Object} config.override.img.additionalStyle - image additional style
 */
export const convertToHtml = (par, config = { override: { img: {} } }) => {
  const paragraphs = parseParagraphs(par);

  console.log('paragraphs:', paragraphs);

  if (paragraphs && paragraphs.length > 0) {
    let returnValue = '';
    paragraphs.map((paragraph) => {
      returnValue += createDivision(paragraph, config) + '\n';
      return null;
    });

    return returnValue;
  } else if (par && !paragraphs) {
    return `<p style="color: white; font-size: 1.6rem;">${par}</p>`;
  }
};
