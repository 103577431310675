import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Popover, Typography, Divider, Button } from 'antd';
import styles from './UserDetails.module.scss';
import { userManagementRoutes } from 'features/user-management';
import { useUserDetailsQuery } from 'features/hooks';

function UserDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useUserDetailsQuery();
  const queryClient = useQueryClient();
  const [showPopover, setShowPopover] = useState(false);

  useEffect(() => {
    queryClient.refetchQueries(['UserDetails-CORE']);
  }, []);

  const singOutHandler = () => {
    dispatch({ type: 'RESET' });
    navigate('/auth/sign-in', { replace: true });
  };

  const manageYourAccountHandler = () => {
    setShowPopover(false);
    navigate(userManagementRoutes.SETTINGS);
  };

  const popupVisibilityHandler = (visible) => {
    setShowPopover(visible);
  };

  return (
    <Popover
      placement="bottomLeft"
      trigger="click"
      open={showPopover}
      onOpenChange={popupVisibilityHandler}
      content={
        <section className={styles.container}>
          <Typography.Text className={styles.username} ellipsis>
            {userDetails.data.username}
          </Typography.Text>
          <Typography.Text type="secondary" className={styles.roleName} ellipsis>
            {userDetails.data.roleName}
          </Typography.Text>

          <Divider className={styles.divider} />

          <Typography.Text>
            {userDetails.data.firstName}
            {userDetails.data.lastName && ` ${userDetails.data.lastName}`}
          </Typography.Text>
          <Typography.Text>{userDetails.data.phoneNumber}</Typography.Text>
          <Typography.Text>{userDetails.data.email}</Typography.Text>

          <Divider className={styles.divider} />

          <Button onClick={manageYourAccountHandler}>Manage your account</Button>

          <Divider className={styles.divider} />
          <Button type="text" onClick={singOutHandler}>
            Sign out
          </Button>
        </section>
      }
    >
      {userDetails.data.username ? (
        <Typography.Link>{userDetails.data.username}</Typography.Link>
      ) : (
        <Typography.Link>Loading...</Typography.Link>
      )}
    </Popover>
  );
}

export default UserDetails;
