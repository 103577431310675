import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

const describeArc = (x, y, radius, startAngle, endAngle) => {
  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);

  var arcSweep = endAngle - startAngle <= 180 ? '0' : '1';

  var d = ['M', start.x, start.y, 'A', radius, radius, 0, arcSweep, 0, end.x, end.y].join(' ');

  return d;
};

const arcQuarter = describeArc(12, 12, 10, 0, 90);

function Loading({
  className = undefined,
  styles = {},
  classNamePosition = 'm-auto',
  classNameColor = 'text-Green-700',
  classNameStroke = 'stroke-2',
  classNameDimension = 'h-[50px] w-[50px]',
  showTrack = false,
}) {
  return (
    <svg
      className={classNames(
        'box-content',
        classNamePosition,
        classNameColor,
        classNameStroke,
        classNameDimension,
        className
      )}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      styles={styles}
    >
      {showTrack && <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" />}
      <path
        className="animate-[spin_1.3s_linear_infinite] origin-center"
        fill="transparent"
        stroke="currentColor"
        d={arcQuarter}
      />
    </svg>
  );
}

Loading.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.object,
  classNamePosition: PropTypes.string,
  classNameColor: PropTypes.string,
  classNameStroke: PropTypes.string,
  classNameDimension: PropTypes.string,
  showTrack: PropTypes.bool,
};

export default Loading;
