import { mainRoutes } from '../../../main/routes';

export const rolesApi = {
  superAdmin: 1000,
  admin: 900,
  contentManager: 700,
  approvers: 500,
  reviewers: 300,
  stickerManager: 120,
  supportTicket: 110,
  verifiedEmail: 100,
  notVerified: 50,
};

export const roles = {
  notVerified: {
    value: rolesApi.notVerified,
    name: 'Email not verified',
    assignRequirements: rolesApi.superAdmin,
    redirectAfterLogin: '/discover',
  },
  verifiedEmail: {
    value: rolesApi.verifiedEmail,
    name: 'Basic',
    assignRequirements: rolesApi.contentManager,
    redirectAfterLogin: '/contact-administrator',
  },
  supportTicket: {
    value: rolesApi.supportTicket,
    name: 'Support Ticket',
    assignRequirements: rolesApi.admin,
    redirectAfterLogin: '/support-v1/support-tickets-v1',
  },
  stickerManager: {
    value: rolesApi.stickerManager,
    name: 'Sticker Manager',
    assignRequirements: rolesApi.admin,
    redirectAfterLogin: '/support-v1/sticker-sets',
  },
  reviewers: {
    value: rolesApi.reviewers,
    name: 'Reviewers',
    assignRequirements: rolesApi.contentManager,
    redirectAfterLogin: '/discover',
  },
  approvers: {
    value: rolesApi.approvers,
    name: 'Approvers',
    assignRequirements: rolesApi.contentManager,
    redirectAfterLogin: '/discover',
  },
  contentManager: {
    value: rolesApi.contentManager,
    name: 'Content manager',
    assignRequirements: rolesApi.admin,
    redirectAfterLogin: '/discover',
  },
  admin: {
    value: rolesApi.admin,
    name: 'Admin',
    assignRequirements: rolesApi.superAdmin,
    redirectAfterLogin: mainRoutes.DISCOVER,
  },
  superAdmin: {
    value: rolesApi.superAdmin,
    name: 'Super admin',
    assignRequirements: 1100,
    redirectAfterLogin: '/discover',
  },
};

export const rolesArray = Object.keys(roles).map((key) => roles[key]);

export const roleNameFromValue = (value) => {
  const role = rolesArray.find((role) => role.value === value);
  return role.name;
};

export const redirectLinkAfterLogin = (userRole) => {
  const role = rolesArray.find((role) => role.value === userRole);
  return role.redirectAfterLogin;
};
