export const mainRoutes = {
  MAIN: '/',
  404: '*',
  AUTH: '/auth',
  CONTACT_ADMINISTRATOR: '/contact-administrator',
  DISCOVER: '/discover',
  MOBILE_USERS: '/mobile-users',
  SUPPORT: '/support-v2',
  ADMIN_USERS: '/admin-users',
  USER_MANAGEMENT: '/user-management',
  LOGS_V1: '/logs-v1',
  SUPPORT_V1: '/support-v1',
  SUPPORT_QRIOS: '/support-qrios',
  LOGS: '/logs-v2',
};

export const mainLabels = {
  [mainRoutes.DISCOVER]: 'Discover',
  [mainRoutes.MOBILE_USERS]: 'Mobile users',
  [mainRoutes.SUPPORT]: 'Support',
  [mainRoutes.ADMIN_USERS]: 'Admin users',
  [mainRoutes.USER_MANAGEMENT]: 'User management',
  [mainRoutes.LOGS_V1]: 'Logs',
  [mainRoutes.SUPPORT_V1]: 'Support',
  [mainRoutes.SUPPORT_QRIOS]: 'Support',
  [mainRoutes.LOGS]: 'Logs',
};

export const withSubRoute = (route, subRoute) => subRoute + route;

export const generateAbsoluteRoutes = (routes = {}, subRoute = '') => {
  let absoluteRoutes = {};
  const keys = Object.keys(routes);
  keys.map((key) => {
    absoluteRoutes[key] = withSubRoute(routes[key], subRoute);
    return null;
  });

  return absoluteRoutes;
};
