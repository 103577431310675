import React from 'react';
import { Typography } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LoadingFlom } from '../../../components';
import { useMobileCheck } from 'core/hooks';
import ProductsListWeb from './ProductsListWeb';
import ProductsListMobile from './ProductsListMobile';

function ProductsList({ isLoading, isError, currentPage, onPageChange, products, ...otherProps }) {
  const isMobile = useMobileCheck();
  const navigate = useNavigate();
  const location = useLocation();

  if (isLoading) {
    return <LoadingFlom />;
  }

  if (isError) {
    return <Typography.Text>Something went wrong, please try again later.</Typography.Text>;
  }

  if (products.length === 0) {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
      return null;
    }
    return <Typography.Text type="danger">No results were found.</Typography.Text>;
  }

  const onClickHandler = (id, name) => {
    navigate(`${location.pathname}/${id}`, { state: { prevSearch: location.search, name } });
  };

  return isMobile ? (
    <ProductsListMobile products={products} onClick={onClickHandler} {...otherProps} />
  ) : (
    <ProductsListWeb products={products} onClick={onClickHandler} {...otherProps} />
  );
}

ProductsList.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  items: PropTypes.array,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
};

ProductsList.defaultProps = {
  isLoading: false,
  isError: false,
  items: [],
  onPageChange: () => {},
};

export default ProductsList;
