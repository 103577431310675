import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { getUserDetails } from 'features/api/userDataApi';
import { authorizationActions } from 'features/auth';

function useUserDetailsQuery() {
  const userId = useSelector((state) => state.authorization.flom.id);
  const dispatch = useDispatch();

  const userDetails = useQuery(['UserDetails-CORE'], () => getUserDetails(userId), {
    initialData: {},
    initialDataUpdatedAt: 0,
    staleTime: 3600000,
    onSuccess: (response) => {
      dispatch(authorizationActions.patchFlomRole(response.role));
    },
  });

  return userDetails;
}

export default useUserDetailsQuery;
