import React, { useRef, useEffect, useState } from 'react';
import { Row, Col, Typography, Spin, Button, Divider, message } from 'antd';
import { useQuery, useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { checkUSSDCode, resendSms } from '../../api/authApi';

const expireTime = 5 * 60 * 1000;
const buttonSmsTimeout = 2 * 60 * 1000;

function USSDVerification({ onBack, credentials, onSignUp, goToSMSVerification }) {
  const btnTimerIdRef = useRef();

  const [SMSButton, setSMSButton] = useState(false);

  const { tempToken, username, ussdCode, maskedPhoneNumber } = credentials;

  useEffect(() => {
    // disable this screen on dev
    if (process.env.REACT_APP_MODE === 'development') {
      switchToSMSVerificationMutation.mutate();
    }

    return () => {
      clearTimeout(btnTimerIdRef.current);
    };
  }, []);

  useEffect(() => {
    if (ussdCode) {
      setSMSButton(false);
      clearTimeout(btnTimerIdRef.current);
      btnTimerIdRef.current = setTimeout(() => {
        setSMSButton(true);
      }, buttonSmsTimeout);
    }
  }, [ussdCode]);

  useQuery(['Check USSD Code', { code: ussdCode }], checkUSSDCode, {
    enabled: !!ussdCode,
    refetchOnWindowFocus: false,
    retry: expireTime / 3000,
    retryDelay: 3000,
    onSuccess: (res) => {
      onSignUp(res, username);
    },
    onError: () => {
      message.error({
        content: 'Session has expired.',
        key: 'USSDVerification',
        duration: 5,
      });
      onBack();
    },
  });

  const switchToSMSVerificationMutation = useMutation(() => resendSms(tempToken, username), {
    onSuccess: () => {
      goToSMSVerification();
    },
    onError: () => {
      message.error({
        content: 'Session has expired.',
        key: 'USSDVerification',
        duration: 5,
      });
      onBack();
    },
  });

  return (
    <>
      <Divider />
      <Row align="middle" justify="center" gutter={[8, 24]}>
        <Col>
          <Typography.Text>Verify from phone number: {maskedPhoneNumber}</Typography.Text>
        </Col>
      </Row>

      <Row align="middle" justify="center" gutter={[8, 24]}>
        <Col>
          <Typography.Link href={`tel:*425*0848*${ussdCode}#`}>
            Please dial *425*0848*{ussdCode}#
          </Typography.Link>
        </Col>
      </Row>

      {SMSButton && (
        <Row align="middle" justify="center" gutter={[8, 24]}>
          <Col>
            {switchToSMSVerificationMutation.isIdle && (
              <Button type="link" onClick={switchToSMSVerificationMutation.mutate}>
                <Typography.Link level={5} color="success" strong>
                  Receive SMS instead?
                </Typography.Link>
              </Button>
            )}
            {switchToSMSVerificationMutation.isLoading && <Spin />}
          </Col>
        </Row>
      )}

      <Divider />

      <Row align="middle" justify="center" gutter={[8, 16]}>
        <Col>
          <Button onClick={onBack}>Back</Button>
        </Col>
      </Row>
    </>
  );
}

USSDVerification.propTypes = {
  onBack: PropTypes.func,
  credentials: PropTypes.object,
  onSignUp: PropTypes.func,
  goToSMSVerification: PropTypes.func,
};

USSDVerification.defaultProps = {
  onBack: () => {},
  credentials: {},
  onSignUp: () => {},
  goToSMSVerification: () => {},
};

export default USSDVerification;
