import React, { memo } from 'react';
import { Route, Navigate, Routes, useLocation } from 'react-router-dom';
import { mainRoutes } from '../routes';
import { Authorization } from 'features/auth';

function UnprotectedRoutes() {
  const location = useLocation();

  return (
    <Routes>
      <Route path={mainRoutes.AUTH + '/*'} element={<Authorization />} />
      <Route
        path="*"
        element={
          <Navigate to={mainRoutes.AUTH} state={{ redirectAfterLogin: location.pathname }} />
        }
      />
    </Routes>
  );
}

export default memo(UnprotectedRoutes);
