import { useCheckRole } from 'features/hoc';
import { supportQriosRoleRules } from './supportQriosRoleRules';

export function useShowSupportQrios() {
  const showSupportQrios = {
    menu: useCheckRole(supportQriosRoleRules.menu),
    letsTalk: useCheckRole(supportQriosRoleRules.letsTalk),
  };

  return showSupportQrios;
}
