import { checkResponse, flomV2, formDataFromObject } from 'core/api';

export const signUpForm = async (data) => {
  const formData = formDataFromObject(data);
  const response = await flomV2.post('/api/v2/admin-page/register', formData);

  if (checkResponse(response)) {
    return response.data.data;
  }
};

export const emailVerificationCode = async (code, userId) => {
  const response = await flomV2.post('/api/v2/admin-page/email/verify', { code, userId });

  if (checkResponse(response)) {
    return response.data.data;
  }
};

export const changeVerificationEmailApi = async (email, userId) => {
  const response = await flomV2.post('/api/v2/admin-page/email/resend', { userId, email });

  if (checkResponse(response)) {
    return response.data.data;
  }
};

export const resendEmailApi = async (userId) => {
  const response = await flomV2.post('/api/v2/admin-page/email/resend', { userId });

  if (checkResponse(response)) {
    return response.data.data;
  }
};

export const emailVerificationToken = async (token) => {
  const response = await flomV2.post('/api/v2/admin-page/email/verify', { token });

  if (checkResponse(response)) {
    return response.data.data;
  }
};
