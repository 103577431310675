import React from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';

const loggedStatus = (hasLoggedIn) => {
  switch (hasLoggedIn) {
    case 1:
      return <Tag color="green">At least once</Tag>;

    case 2:
      return <Tag color="red">Never</Tag>;

    case 3:
      return <Tag color="yellow">Old</Tag>;

    case 4:
      return <Tag color="orange">Shadow</Tag>;

    default:
      return <Tag color="default">Unknown</Tag>;
  }
};

function LoggedStatus({ hasLoggedIn }) {
  return loggedStatus(hasLoggedIn);
}

LoggedStatus.propTypes = {
  hasLoggedIn: PropTypes.oneOf([1, 2, 3, 4]),
};

LoggedStatus.defaultProps = {
  hasLoggedIn: undefined,
};

export default LoggedStatus;
