import React from 'react';
import { Typography } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMobileCheck } from 'core/hooks';
import { LoadingFlom } from 'features/components';
import UsersListWeb from './UsersListWeb';
import UsersListMobile from './UsersListMobile';

function UsersList({ users, isLoading, isError }) {
  const isMobile = useMobileCheck();
  const navigate = useNavigate();
  const location = useLocation();

  if (isLoading) {
    return <LoadingFlom />;
  }

  if (isError) {
    return <Typography.Text>Something went wrong, please try again later.</Typography.Text>;
  }

  if (users.length === 0) {
    return <Typography.Text type="danger">No results were found.</Typography.Text>;
  }

  const onClickHandler = (id, username) => {
    navigate(`${location.pathname}/${id}`, { state: { prevSearch: location.search, username } });
  };

  return isMobile ? (
    <UsersListMobile users={users} onClick={onClickHandler} />
  ) : (
    <UsersListWeb users={users} onClick={onClickHandler} />
  );
}

UsersList.propTypes = {
  users: PropTypes.array,
};

UsersList.defaultProps = {};

export default UsersList;
