import { mainRoutes, generateAbsoluteRoutes } from 'features/main/routes';

export const logsRoutesRelative = {
  404: '/*',
  DISCOVER: '/discover',
  DISCOVER_DETAILS: '/discover/:id',
  MODERATORS: '/moderators',
  PAYMENT: '/payment',
};

export const logsRoutes = generateAbsoluteRoutes(logsRoutesRelative, mainRoutes.LOGS_V1);

export const logsLabels = {
  [logsRoutes.DISCOVER]: 'Discover moderation',
  [logsRoutes.MODERATORS]: 'Moderators',
  [logsRoutes.PAYMENT]: 'Payment changes',
};

export const breadcrumbLabels = {
  discover: ['flom v1', logsLabels[logsRoutes.DISCOVER]],
  moderators: ['flom v1', logsLabels[logsRoutes.MODERATORS]],
  payment: ['flom v1', logsLabels[logsRoutes.PAYMENT]],
};

export const breadcrumbLinks = {
  discover: [undefined, logsRoutes.DISCOVER],
  moderators: [undefined, logsRoutes.MODERATORS],
  payment: [undefined, logsRoutes.PAYMENT],
};
