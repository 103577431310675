import React, { useState } from 'react';
import { Image } from 'antd';
import RenderSmoothImage from 'render-smooth-image-react';
import PropTypes from 'prop-types';

import styles from './ImageWithLoading.module.scss';

function ImageWithLoading({ src }) {
  const [imageIsLoaded, setImageIsLoaded] = useState(false);

  if (imageIsLoaded) {
    return <Image className={styles.image} src={src} />;
  }

  const setImage = () => {
    setImageIsLoaded(true);
  };

  return <RenderSmoothImage src={src} alt="Image error" onLoad={setImage} />;
}

ImageWithLoading.propTypes = {
  src: PropTypes.string,
};

export default ImageWithLoading;
