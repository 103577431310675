import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import logo from '../../assets/logo.svg';
import './header.scss';

function Header({ middleContent, rightContent }) {
  const navigate = useNavigate();

  const onLogoClickHandler = () => {
    navigate('/');
  };

  return (
    <header className="header">
      <div className="header-item">
        <img src={logo} alt="Logo" onClick={onLogoClickHandler} style={{ cursor: 'pointer' }} />
      </div>
      <div className="header-item">{middleContent}</div>
      <div className="header-item">{rightContent}</div>
    </header>
  );
}

Header.propTypes = {
  middleContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  rightContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Header.defaultProps = {};

export default Header;
