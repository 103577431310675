import { useCheckRole } from 'features/hoc';
import { mobileUsersRoleRules } from './mobileUsersRoleRules';

export function useShowMobileUsers() {
  const showMobileUsers = {
    menu: useCheckRole(mobileUsersRoleRules.menu),
    mobileUsers: useCheckRole(mobileUsersRoleRules.mobileUsers),
    merchantReview: useCheckRole(mobileUsersRoleRules.merchantReview),
  };

  return showMobileUsers;
}
