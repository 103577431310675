import React, { useState } from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import Verification from './Verification';
import NewEmail from './NewEmail';

const selectTitle = (type) => {
  switch (type) {
    case 'verification':
      return 'Email verification';
    case 'changeEmail':
      return 'Change verification email';
    default:
      return 'Email verification';
  }
};

function EmailVerificationModal({ visible, id, email, onCancel, onFinish }) {
  const [type, setType] = useState('verification'); //'verification', 'changeEmail'
  const [changedEmail, setChangedEmail] = useState();

  const changeToNewEmail = () => {
    setChangedEmail();
    setType('changeEmail');
  };

  const changeToVerification = (email) => {
    setChangedEmail(email);
    setType('verification');
  };

  const cancelChangeToNewEmail = () => {
    setChangedEmail();
    setType('verification');
  };

  const onFinishHandler = () => {
    setChangedEmail();
    onFinish();
  };

  const onCancelHandler = () => {
    setChangedEmail();
    onCancel();
  };

  return (
    <Modal
      title={selectTitle(type)}
      open={visible}
      maskClosable={false}
      closable={false}
      footer={null}
    >
      {visible && type === 'verification' && (
        <Verification
          id={id}
          email={changedEmail || email}
          onCancel={onCancelHandler}
          onFinish={onFinishHandler}
          onChangeEmail={changeToNewEmail}
        />
      )}
      {visible && type === 'changeEmail' && (
        <NewEmail onCancel={cancelChangeToNewEmail} onFinish={changeToVerification} id={id} />
      )}
    </Modal>
  );
}

EmailVerificationModal.propTypes = {
  visible: PropTypes.bool,
  id: PropTypes.string,
  email: PropTypes.string,
};

EmailVerificationModal.defaultProps = {
  visible: true,
};

export default EmailVerificationModal;
