import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Layout } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Sider.module.scss';
import { patchNavigation } from '../../navigationSlice';

import { useFlomV1Sider, useFlomV2Sider, useQriosSider } from 'features/layout/hooks';

function Sider() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { siderSelectedKey, siderOpenedKeys, breakpoint } = useSelector(
    (state) => state.navigation
  );
  const userRole = useSelector((state) => state.authorization.flom.role);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const flomV1Sider = useFlomV1Sider();
  const flomV2Sider = useFlomV2Sider();
  const qriosSider = useQriosSider();

  const onClickHandler = (event) => {
    if (breakpoint) {
      setIsCollapsed(true);
    }
    dispatch(patchNavigation({ siderSelectedKey: event.key }));
    navigate(event.key);
  };

  const onOpenChangeHandler = (openedKeys) => {
    dispatch(patchNavigation({ siderOpenedKeys: openedKeys }));
  };

  const onCollapseHandler = (collapseState) => {
    setIsCollapsed(collapseState);
  };

  const menuItems = useMemo(() => [...flomV1Sider, ...flomV2Sider, ...qriosSider], [userRole]);

  return (
    <Layout.Sider
      breakpoint="sm"
      collapsedWidth="0"
      zeroWidthTriggerStyle={{ color: 'black', top: 0, backgroundColor: 'transparent' }}
      onCollapse={onCollapseHandler}
      collapsed={isCollapsed}
      className={breakpoint ? '' : styles.siderContainer}
      width={210}
    >
      <Menu
        mode="inline"
        onOpenChange={onOpenChangeHandler}
        openKeys={isCollapsed ? [] : siderOpenedKeys}
        selectedKeys={isCollapsed ? [] : siderSelectedKey}
        onClick={onClickHandler}
        items={menuItems}
        className="pb-20"
      />
    </Layout.Sider>
  );
}

export default Sider;
