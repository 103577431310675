import React from 'react';
import Lottie from 'react-lottie-player';
import lottieJson from './logo-animation.json';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import styles from './LoadingFlom.module.scss';

function LoadingFlom({ label, loading }) {
  if (!loading) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Lottie loop animationData={lottieJson} play className={styles.animation} />
      <Typography.Text>{label}</Typography.Text>
    </div>
  );
}

LoadingFlom.propTypes = {
  loading: PropTypes.bool,
  label: PropTypes.string,
};

LoadingFlom.defaultProps = {
  loading: true,
};

export default LoadingFlom;
