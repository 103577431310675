import React from 'react';
import { Layout as AntLayout } from 'antd';
import styles from './Layout.module.scss';
import { Content, Header, UserDetails } from '../../components';
import { Sider } from '../sider';

function Layout({ children }) {
  return (
    <AntLayout className="w-screen h-screen">
      <Header rightContent={<UserDetails />} />
      <AntLayout>
        <Sider />
        <AntLayout className={styles.layout}>
          <Content>{children}</Content>
        </AntLayout>
      </AntLayout>
    </AntLayout>
  );
}

export default Layout;
