import { useCheckRole } from 'features/hoc';
import { discoverRoleRules } from './discoverRoleRules';

export function useShowDiscover() {
  const showDiscover = {
    menu: useCheckRole(discoverRoleRules.menu),
    products: useCheckRole(discoverRoleRules.products),
    videos: useCheckRole(discoverRoleRules.videos),
    audio: useCheckRole(discoverRoleRules.audio),
    writings: useCheckRole(discoverRoleRules.writings),
    expos: useCheckRole(discoverRoleRules.expos),
    productCategoryManagement: useCheckRole(discoverRoleRules.productCategoryManagement),

    // individual page features
    isReviewer: useCheckRole(discoverRoleRules.isReviewer),
  };

  return showDiscover;
}
