import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { paramsFromQuery } from 'core/api';
import { Pagination } from 'features/components';
import { SetNavigation } from 'features/layout';
import styles from './UserManagement.module.scss';
import { UsersList, UsersFilters } from '../../components';
import { breadcrumbLabels, breadcrumbLinks, usersRoutes } from '../../../routes';
import { useParametersFromLocationSearch } from 'core/hooks';
import * as usersServices from '../../api/usersApi';
import { Typography } from 'antd';

function UserManagement(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useParametersFromLocationSearch();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (searchParams.page) {
      setCurrentPage(+searchParams.page);
    } else {
      setCurrentPage(1);
    }
  }, [searchParams]);

  const userList = useQuery(['UserList', searchParams], usersServices.getUsersList, {
    initialData: { users: [] },
    initialDataUpdatedAt: 0,
  });

  const onFilterChangeHandler = (filters) => {
    navigate(
      {
        pathname: location.pathname,
        search: paramsFromQuery({ ...filters, page: 1 }).toString(),
      },
      { replace: true }
    );
    setCurrentPage(1);
  };

  const onPageChangeHandler = (pageNumber) => {
    setCurrentPage(pageNumber);
    navigate(
      {
        pathname: location.pathname,
        search: paramsFromQuery({ ...searchParams, page: pageNumber }).toString(),
      },
      { replace: true }
    );
  };

  return (
    <SetNavigation
      breadcrumbsLinks={breadcrumbLinks.userManagement}
      breadcrumbsLabels={breadcrumbLabels.userManagement}
      siderSelectedKey={usersRoutes.USER_MANAGEMENT}
    >
      <div className={styles.container}>
        <UsersFilters
          onFilterChangeHandler={onFilterChangeHandler}
          totalItems={userList.data?.total}
          itemsPerPage={10}
          currentPage={currentPage}
          onPageChange={onPageChangeHandler}
          isLoading={userList.isLoading}
        />
        <Pagination
          totalItems={userList.data?.total}
          itemsPerPage={10}
          currentPage={currentPage}
          onPageChange={onPageChangeHandler}
        />
        <UsersList
          isLoading={userList.isLoading}
          isError={userList.isError}
          users={userList.data?.users}
        />
        <Pagination
          totalItems={userList.data?.total}
          itemsPerPage={10}
          currentPage={currentPage}
          onPageChange={onPageChangeHandler}
        />

        <div className="flex-1 flex flex-col">
          <Typography.Link>Active user</Typography.Link>
          <Typography.Text type="warning">Deleted user</Typography.Text>
          <Typography.Text type="secondary">Flomer</Typography.Text>
        </div>
      </div>
    </SetNavigation>
  );
}

export default UserManagement;
