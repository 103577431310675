import React from 'react';
import { Typography } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function TextRedirect({ text, redirectQuery, path }) {
  const navigate = useNavigate();
  const location = useLocation();

  const onClickHandler = (event) => {
    event.stopPropagation();
    navigate({
      pathname: path ? path : location.pathname,
      search: `?${redirectQuery}=${encodeURIComponent(text)}`,
    });
  };

  return (
    <Typography.Link onClick={onClickHandler}>
      <u>{text}</u>
    </Typography.Link>
  );
}

TextRedirect.propTypes = {
  text: PropTypes.string,
  redirectQuery: PropTypes.string,
  path: PropTypes.string,
};

export default TextRedirect;
