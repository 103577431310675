import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  authorized: false,
  flom: { id: '', accessToken: null, role: 0, time: 0 },
};

export const authorizationSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    postAuthorization: (state, action) => {
      return action.payload;
    },
    patchFlomRole: (state, action) => {
      state.flom.role = action.payload;
    },
    resetToken: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase('RESET', () => initialState);
  },
});

export const { resetToken, postAuthorization, patchFlomRole } = authorizationSlice.actions;
export default authorizationSlice.reducer;
