export const transactionTypes = {
  topUp: { value: 1, name: 'Top-up' },
  data: { value: 2, name: 'Data' },
  bless: { value: 3, name: 'Bless' },
  marketplace: { value: 4, name: 'Marketplace' },
  membership: { value: 5, name: 'Membership' },
  cash: { value: 6, name: 'Cash' },
  creditPackage: { value: 7, name: 'Credit Package' },
  creditTransfer: { value: 8, name: 'Credit Transfer' },
  sprayBless: { value: 9, name: 'Spray Bless' },
  sats: { value: 10, name: 'Sats Transfer' },
  satsPurchase: { value: 11, name: 'Sats Purchase' },
  mediaContent: { value: 12, name: 'Media Content' },

  bonusDataPackage: { value: 98, name: 'Bonus data package' },
  payout: { value: 99, name: 'Payout' },
};

export const paymentMethods = {
  creditCard: { value: 1, name: 'Credit Card' },
  payPal: { value: 2, name: 'PayPal' },
  bankAccount: { value: 3, name: 'Bank account' },
  creditBalance: { value: 4, name: 'Credit balance' },
  satsBalance: { value: 5, name: 'Sats balance' },
  applePay: { value: 6, name: 'Apple Pay' },
  googlePay: { value: 7, name: 'Google Pay' },
  internal: { value: 99, name: 'Internal' },
};

export const sourceTypes = {
  app: { value: 'user', name: 'app' },
  web: { value: 'guest', name: 'web' },
};

export const transactionStatus = {
  prepayment: { value: 1, name: 'Waiting for payment' },
  waitingForFulfillment: { value: 2, name: 'Waiting for fulfillment' },
  complete: { value: 3, name: 'Sent' },
  fulfillmentFailed: { value: 4, name: 'Fulfillment failed' },
  paymentFailed: { value: 5, name: 'Payment failed' },
  voided: { value: 6, name: 'Voided' },
};

const typesVariants = (types = {}) => {
  const valueNameObj = {};
  const labelValueArray = [];

  for (const key in types) {
    const { value, name } = types[key];
    valueNameObj[value] = name;
    labelValueArray.push({ label: name, value });
  }

  return { valueNameObj, labelValueArray };
};

export const {
  valueNameObj: transactionTypesValueNameObj,
  labelValueArray: transactionTypesArray,
} = typesVariants(transactionTypes);
