import React, { memo } from 'react';
import { Row, Col, Typography, Button } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import styles from './ProductsListWeb.module.scss';
import ProductStatus from '../productStatus/ProductStatus';
import ProductImage from '../productImage/ProductImage';
import ProcessingStatus from '../processingStatus/ProcessingStatus';
import { getProductThumbnail } from '../../helpers/fileHelpers';

function ProductsListWeb({ products, productTitle, onClick }) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Row justify="center">
      <Col>
        <table className={styles.table}>
          <thead>
            <tr>
              <th scope="col">
                <Typography.Title level={5}>Date:</Typography.Title>
              </th>
              <th scope="col">
                <Typography.Title level={5}>Image:</Typography.Title>
              </th>
              <th scope="col">
                <Typography.Title level={5}>{productTitle}:</Typography.Title>
              </th>
              <th scope="col">
                <Typography.Title level={5}>Username:</Typography.Title>
              </th>
              <th scope="col">
                <Typography.Title level={5}>Status:</Typography.Title>
              </th>
              <th scope="col">
                <Typography.Title level={5}>Processing:</Typography.Title>
              </th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => {
              const thumbnail = getProductThumbnail(product);

              return (
                <tr
                  key={product._id}
                  className={styles.productContainer}
                  onClick={() => onClick(product._id, product.name)}
                >
                  <td className={styles.titleContainer}>
                    <Typography.Text>{dayjs(product.created).format('DD/MM/YY')}</Typography.Text>
                  </td>
                  <td>
                    {thumbnail && (
                      <div className={styles.imageContainer}>
                        <ProductImage src={thumbnail} productType={product.type} />
                      </div>
                    )}
                  </td>
                  <td className={styles.titleContainer}>
                    <Typography.Text>{product.name}</Typography.Text>
                  </td>
                  <td className={styles.usernameContainer}>
                    <Button
                      type="link"
                      style={{ paddingLeft: 0 }}
                      onClick={(event) => {
                        event.stopPropagation();
                        navigate({
                          pathname: location.pathname,
                          search: `?username=${product.owner.username}&page=1`,
                        });
                      }}
                    >
                      <u>{product.owner.username}</u>
                    </Button>
                  </td>
                  <td className={styles.statusContainer}>
                    <ProductStatus status={product.moderation?.status} />
                  </td>
                  <td className={styles.statusContainer}>
                    <ProcessingStatus mediaProcessingInfo={product.mediaProcessingInfo} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Col>
    </Row>
  );
}

ProductsListWeb.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  products: PropTypes.array,
  productTitle: PropTypes.oneOf([
    'Product title',
    'Video title',
    'Podcast title',
    'Story title',
    'Expo title',
  ]),
  onClick: PropTypes.func,
};

ProductsListWeb.defaultProps = {
  isLoading: false,
  isError: false,
  products: [],
  productTitle: 'Product title',
  onClick: () => {},
};

export default memo(ProductsListWeb);
