import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Form, Typography } from 'antd';
import { UserOutlined, KeyOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { ReCaptchaFormItem, UsernameInput, PasswordCurrentInput } from 'features/components';
import { authRoutes } from '../../../routes';
import { errorCodes } from '../../api/authApi';

function LoginForm({ onFinish, error, loading }) {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinishHandler = () => {
    onFinish(form.getFieldsValue());
    form.setFieldsValue({ reCaptcha: undefined });
  };

  const validateError = (error) => {
    if (error && error.code) {
      if (
        error.code === errorCodes.smsVerificationRequired ||
        error.code === errorCodes.smsVerificationWrong
      ) {
        return;
      }

      if (error.code === 443403) {
        return 'Unable to verify your phone number.';
      }

      return 'error';
    }
  };

  const singUpHandler = () => {
    navigate(authRoutes.SIGN_UP);
  };

  const forgotPasswordHandler = () => {
    navigate(authRoutes.RESET_PASSWORD_EMAIL);
  };

  return (
    <>
      <Form
        name="login"
        form={form}
        layout="vertical"
        onFinish={onFinishHandler}
        requiredMark={false}
      >
        <Form.Item
          label="Username:"
          name="username"
          rules={[{ required: true, message: ' ' }]}
          validateStatus={validateError(error)}
        >
          <UsernameInput size="large" autoFocus prefix={<UserOutlined />} />
        </Form.Item>

        <Form.Item
          label="Password:"
          name="password"
          rules={[{ required: true, message: ' ' }]}
          validateStatus={validateError(error)}
        >
          <PasswordCurrentInput size="large" prefix={<KeyOutlined />} />
        </Form.Item>

        <ReCaptchaFormItem />

        <Form.Item>
          <Row align="middle" justify="center">
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Sign in
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        {validateError(error) === 'error' && (
          <Row justify="center">
            <Col>
              <Typography.Text type="danger">
                The username or password is incorrect!
              </Typography.Text>
            </Col>
          </Row>
        )}

        {validateError(error) && (
          <Row justify="center">
            <Col>
              <Typography.Text type="danger">{validateError(error)}</Typography.Text>
            </Col>
          </Row>
        )}
      </Form>

      <Row justify="center">
        <Col>
          <Typography.Text>
            Don't have an account?{' '}
            <Typography.Link strong onClick={singUpHandler}>
              Sign up
            </Typography.Link>
          </Typography.Text>
        </Col>
      </Row>

      <Row justify="center">
        <Col>
          <Typography.Link onClick={forgotPasswordHandler}>Forgot password?</Typography.Link>
        </Col>
      </Row>
    </>
  );
}

LoginForm.propTypes = {
  onFinish: PropTypes.func,
  error: PropTypes.object,
  loading: PropTypes.bool,
};

LoginForm.defaultProps = {
  onFinish: () => {},
  error: {},
  loading: false,
};

export default LoginForm;
