import React, { memo } from 'react';
import { Typography, Card } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import styles from './ProductsListMobile.module.scss';
import ProductStatus from '../productStatus/ProductStatus';
import { getProductThumbnail } from '../../helpers/fileHelpers';
import ProductImage from '../productImage/ProductImage';
import ProcessingStatus from '../processingStatus/ProcessingStatus';

function ProductsListMobile({ products, productTitle, onClick }) {
  const navigate = useNavigate();
  const location = useLocation();

  return products.map((product) => {
    const thumbnail = getProductThumbnail(product);
    return (
      <Card
        className={styles.card}
        onClick={() => onClick(product._id, product.name)}
        key={product._id}
        cover={
          thumbnail && (
            <div className={styles.imageContainer}>
              <ProductImage src={thumbnail} productType={product.type} />
            </div>
          )
        }
      >
        <div className={styles.textContainer}>
          <Typography.Text className={styles.title} strong>
            Date:
          </Typography.Text>
          <Typography.Text>{dayjs(product.created).format('D MMMM YYYY')}</Typography.Text>

          <Typography.Text className={styles.title} strong>
            {productTitle}:
          </Typography.Text>
          <Typography.Text>{product.name}</Typography.Text>

          <Typography.Text className={styles.title} strong>
            Username:
          </Typography.Text>
          <Typography.Link
            onClick={(event) => {
              event.stopPropagation();
              navigate({
                pathname: location.pathname,
                search: `?username=${product.owner.username}&page=1`,
              });
            }}
          >
            <u>{product.owner.username}</u>
          </Typography.Link>

          <Typography.Text className={styles.title} strong>
            Status:
          </Typography.Text>
          <div>
            <ProductStatus status={product.moderation?.status} />
          </div>

          <Typography.Text className={styles.title} strong>
            Processing:
          </Typography.Text>
          <div>
            <ProcessingStatus mediaProcessingInfo={product.mediaProcessingInfo} />
          </div>
        </div>
      </Card>
    );
  });
}

ProductsListMobile.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  products: PropTypes.array,
  productTitle: PropTypes.oneOf([
    'Product title',
    'Video title',
    'Podcast title',
    'Story title',
    'Expo title',
  ]),
  onClick: PropTypes.func,
};

ProductsListMobile.defaultProps = {
  isLoading: false,
  isError: false,
  products: [],
  productTitle: 'Product title',
  onClick: () => {},
};

export default memo(ProductsListMobile);
