import React, { Component } from 'react';
import { Button, Typography } from 'antd';
import { queryClient } from 'core/main/AppProvider';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.

    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);

    queryClient.clear();
    this.setState({ error, errorInfo });
  }

  clearError = () => {
    this.setState({ hasError: false });
  };

  goBackHandler = () => {
    window.history.back();
    this.clearError();
  };

  onRoutChangeHandler = () => {
    this.clearError();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.hasError === false && this.state.hasError === true) {
      window.navigation.addEventListener('navigate', this.onRoutChangeHandler);
    } else if (prevState.hasError === true && this.state.hasError === false) {
      window.navigation.removeEventListener('navigate', this.onRoutChangeHandler);
    }
  }

  componentWillUnmount() {
    window.navigation.removeEventListener('navigate', this.onRoutChangeHandler);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <Typography.Title level={3}>
            Please check your network connection, or try again later.
          </Typography.Title>
          <div className="m-auto">
            <Button type="primary" size="large" onClick={this.goBackHandler}>
              Go back
            </Button>
          </div>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
