import { flomV2, checkResponse } from 'core/api';

export const errorCodes = {
  smsVerificationRequired: 443402,
  smsVerificationWrong: 443387,
  USSDVerificationRequired: 443399,
};

const loginFlom = async (username, password, reCaptcha) => {
  const response = await flomV2.post('/api/v2/admin-page/login', {
    username,
    password,
    reCaptcha,
  });

  if (
    checkResponse(response, [
      errorCodes.smsVerificationRequired,
      errorCodes.USSDVerificationRequired,
    ])
  ) {
    return {
      ...response.data.data,
      time: response.data.time,
      code: response.data.code,
      ...(response.data.data.code && { ussdCode: response.data.data.code }),
    };
  }
};

export const loginApi = async (...args) => {
  let tokens = {};
  tokens.flom = await loginFlom(...args);

  return tokens;
};

export const smsVerification = async (tempToken, username, code) => {
  const response = await flomV2.post('/api/v2/admin-page/login/2fa', {
    tempToken,
    username,
    code,
  });

  if (checkResponse(response)) {
    return { flom: { ...response.data.data, time: response.data.time } };
  }
};

export const resendSms = async (tempToken, username) => {
  const response = await flomV2.post('/api/v2/admin-page/login/2fa/resend', {
    tempToken,
    username,
  });

  if (checkResponse(response)) {
    return response.data.data;
  }
};

export const checkUSSDCode = async ({ queryKey }) => {
  const { code } = queryKey[1];

  const response = await flomV2.get(`/api/v2/ussd2/check/${code}`);

  if (checkResponse(response)) {
    return { flom: { ...response.data.data, time: response.data.time } };
  }
};
