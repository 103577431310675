import React from 'react';
import { Form, Select } from 'antd';
import { transactionStatus } from '../../const';

function TransactionStatusFormItem() {
  return (
    <Form.Item name="status" label="Status" initialValue="">
      <Select>
        <Select.Option value="">All</Select.Option>
        <Select.Option value={`${transactionStatus.prepayment.value}`}>
          {transactionStatus.prepayment.name}
        </Select.Option>
        <Select.Option value={`${transactionStatus.waitingForFulfillment.value}`}>
          {transactionStatus.waitingForFulfillment.name}
        </Select.Option>
        <Select.Option value={`${transactionStatus.complete.value}`}>
          {transactionStatus.complete.name}
        </Select.Option>
        <Select.Option value={`${transactionStatus.fulfillmentFailed.value}`}>
          {transactionStatus.fulfillmentFailed.name}
        </Select.Option>
        <Select.Option value={`${transactionStatus.paymentFailed.value}`}>
          {transactionStatus.paymentFailed.name}
        </Select.Option>
        <Select.Option value={`${transactionStatus.voided.value}`}>
          {transactionStatus.voided.name}
        </Select.Option>
      </Select>
    </Form.Item>
  );
}

export default TransactionStatusFormItem;
