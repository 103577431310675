import React, { useEffect } from 'react';
import { Button, Modal, Input, Form } from 'antd';
import PropTypes from 'prop-types';

function ChangeEmailModal({ visible, onCancel, onFinish, isLoading }) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (form && visible) {
      form.resetFields();
    }
  }, [visible]);

  const onFinishHandler = () => {
    onFinish(form.getFieldValue('email'));
  };

  return (
    <Modal
      title="Change verification email"
      open={visible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={isLoading} onClick={form.submit}>
          Submit
        </Button>,
      ]}
    >
      <Form layout="vertical" onFinish={onFinishHandler} form={form}>
        <Form.Item
          label="New email:"
          name="email"
          validateTrigger="onSubmit"
          rules={[
            { required: true, message: '*email is required field' },
            {
              validator: (_, value) => {
                const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i;

                if (!value) {
                  return Promise.resolve();
                }
                if (regex.test(String(value).toLowerCase())) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(`The format of the email address isn't correct.`));
              },
            },
          ]}
        >
          <Input autoComplete="email" name="email" type="email" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

ChangeEmailModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onFinish: PropTypes.func,
};

ChangeEmailModal.defaultProps = {
  visible: false,
  onCancel: () => {},
  onFinish: () => {},
};

export default ChangeEmailModal;
