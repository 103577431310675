import React, { useState } from 'react';
import { Input, Button, Form, Typography, Row, Col, message } from 'antd';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { signUpApi } from 'features/auth';

function Verification({ onCancel, onFinish, id, email, onChangeEmail }) {
  const [form] = Form.useForm();
  const [error, setError] = useState(false);

  const emailVerification = useMutation(({ code }) => signUpApi.emailVerificationCode(code, id), {
    onMutate: () => {
      message.loading({ key: 'emailVerification', content: 'Verifying email.', duration: 100 });
    },
    onSuccess: () => {
      form.resetFields();
      message.success({ key: 'emailVerification', content: 'Email verified.', duration: 5 });
      onFinish();
    },
    onError: () => {
      setError(true);
      message.error({
        key: 'emailVerification',
        content: 'Email verification failed.',
        duration: 5,
      });
      form.validateFields();
    },
  });

  const resendEmail = useMutation(() => signUpApi.resendEmailApi(id));

  const onFinishHandler = () => {
    emailVerification.mutate({ code: form.getFieldValue('emailCode') });
  };

  const onResetErrorHandler = () => {
    if (error) {
      setError(false);
      form.validateFields();
    }
  };

  const onCancelHandler = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <>
      <Form form={form} layout="vertical" name="signUp" onFinish={onFinishHandler}>
        <Form.Item
          label={
            <Typography.Text>
              Email code: {email && <Typography.Text type="secondary">({email})</Typography.Text>}
            </Typography.Text>
          }
          name="emailCode"
          rules={[
            { required: true, message: 'Please enter the code from the email.' },
            {
              validator: () => {
                if (error) {
                  return Promise.reject(new Error('Invalid or expired code.'));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input autoComplete="emailCode" name="emailCode" onChange={onResetErrorHandler} />
        </Form.Item>
      </Form>

      <Row justify="center">
        <Col>
          <Typography.Text>Didn't get your email?</Typography.Text>
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <Typography.Text>
            <Typography.Link onClick={resendEmail.mutate} disabled={resendEmail.isLoading}>
              Resend the code
            </Typography.Link>
            {onChangeEmail && (
              <>
                {' '}
                or{' '}
                <Typography.Link onClick={onChangeEmail}>update your email address</Typography.Link>
              </>
            )}
            .
          </Typography.Text>
        </Col>
      </Row>

      <Row justify="end" style={{ marginTop: 16 }}>
        <Col>
          <Button onClick={onCancelHandler} disabled={emailVerification.isLoading}>
            Cancel
          </Button>
        </Col>
        <Col style={{ marginLeft: 8 }}>
          <Button onClick={form.submit} type="primary" loading={emailVerification.isLoading}>
            Submit
          </Button>
        </Col>
      </Row>
    </>
  );
}

Verification.propTypes = {
  onChangeEmail: PropTypes.func,
  id: PropTypes.string,
  email: PropTypes.string,

  onCancel: PropTypes.func,
  onFinish: PropTypes.func,
};

Verification.defaultProps = {
  onCancel: () => {},
  onFinish: () => {},
};

export default Verification;
