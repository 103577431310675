import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { convertToHtml } from './helpers';
import './RichTextPreview.scss';

import { useSelector } from 'react-redux';

const richTextDimensions = (screenWidth, maxWidth) => {
  const aspectRatio = 1.93;

  let width = screenWidth;
  let height = width * aspectRatio;

  if (width > maxWidth) {
    width = maxWidth;
    height = width * aspectRatio;
  }

  return { width, height };
};

const calculateMaxWidth = (screenWidth) => {
  if (screenWidth <= 576) {
    return screenWidth;
  }
  return screenWidth * 0.8;
};

function RichTextPreview({ paragraphs, title, backgroundImage }) {
  const {
    width: contentScreenWidth,
    //  height: contentScreenHeight
  } = useSelector((state) => state.layout.content);
  let override = {};

  const contentScreenHeight = 550;

  const { width, height } = richTextDimensions(
    contentScreenWidth,
    Math.min(800, calculateMaxWidth(contentScreenWidth))
  );
  const html = convertToHtml(paragraphs, { override, width, height });

  let richTextContentBackground = override.backColor
    ? { backgroundColor: override.backColor }
    : {
        backgroundImage: `url(${backgroundImage}), linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(124, 124, 124, 0.6) 100%)`,
      };

  return (
    <div className="grid-single-column justify-self-center">
      <div style={{ height: contentScreenHeight }} className="rich-text-container">
        <div
          className="rich-text-content"
          style={{
            width,
            minHeight: contentScreenHeight - 20,
            ...richTextContentBackground,
          }}
        >
          <p className="title" style={{ color: override.textColor }}>
            {title}
          </p>
          <div className="rich-text" dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </div>
    </div>
  );
}

RichTextPreview.propTypes = {
  paragraphs: PropTypes.string,
  stylesTitle: PropTypes.string,
  title: PropTypes.string,
  backgroundImage: PropTypes.string,
};

RichTextPreview.defaultProps = {
  paragraphs: undefined,
  stylesTitle: '',
  title: '',
  backgroundImage: '',
};

export default memo(RichTextPreview);
