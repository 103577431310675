import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, Button } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { AuthLayout } from 'features/layout';
import { authRoutes } from '../../../routes';
import styles from './VerificationByUrl.module.scss';
import { LoadingFlom } from 'features/components';
import { emailVerificationToken } from '../../api/singUpApi';

function VerificationByUrl() {
  const navigate = useNavigate();
  const params = useParams();
  const [timer, setTimer] = useState(0);
  const isAuthorized = useSelector((state) => state.authorization.authorized);

  const emailVerification = useMutation(({ token }) => emailVerificationToken(token), {
    onSuccess: () => {
      setTimer(10);
      setTimeout(() => {
        if (isAuthorized) {
          navigate(-1);
        } else {
          navigate(authRoutes.SIGN_IN, { replace: true });
        }
      }, 10500);
    },
  });

  useEffect(() => {
    emailVerification.mutate({ token: params.token });
  }, []);

  useEffect(() => {
    let timerCount;
    if (timer !== 0) {
      timerCount = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
    return () => {
      clearTimeout(timerCount);
    };
  }, [timer]);

  const singUpHandler = () => {
    navigate(authRoutes.SIGN_UP, { replace: true });
  };

  const singInHandler = () => {
    navigate(authRoutes.SIGN_IN, { replace: true });
  };

  const headerButton = (success) => {
    let headerButton = (
      <Button onClick={singUpHandler} type="link">
        Sign up
      </Button>
    );
    if (success) {
      headerButton = (
        <Button onClick={singInHandler} type="link">
          Sign in
        </Button>
      );
    }

    return headerButton;
  };

  const content = (
    <div className={styles.mainContainer}>
      {emailVerification.isLoading && <LoadingFlom label="Verifying email address, please wait." />}
      {emailVerification.isError && (
        <>
          <Typography.Title level={3}>Email verification failed.</Typography.Title>
          <Typography.Text>Please try again.</Typography.Text>
          <Typography.Text>
            For a new registration, your username will be available after 10 minutes.
          </Typography.Text>
        </>
      )}
      {emailVerification.isSuccess && (
        <>
          <Typography.Title type="flom-green" level={3}>
            Email verified!
          </Typography.Title>
          <Typography.Text>
            Email successfully verified. You will be redirected in {timer}
          </Typography.Text>
        </>
      )}
    </div>
  );

  if (isAuthorized) {
    return content;
  }

  return (
    <AuthLayout
      header={{
        rightContent: headerButton(emailVerification.isSuccess),
      }}
    >
      {content}
    </AuthLayout>
  );
}

export default VerificationByUrl;
