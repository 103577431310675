import React from 'react';
import { Typography } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function UsernameFilterRedirect({ username, redirectQuery, path }) {
  const navigate = useNavigate();
  const location = useLocation();

  if (!username) {
    return (
      <>
        <Typography.Text>Guest user</Typography.Text>
      </>
    );
  }

  const onClickHandler = (event) => {
    event.stopPropagation();
    navigate({
      pathname: path ? path : location.pathname,
      search: `?${redirectQuery}=${encodeURIComponent(username)}`,
    });
  };

  return (
    <>
      <Typography.Link onClick={onClickHandler}>
        <u>{username}</u>
      </Typography.Link>
    </>
  );
}

UsernameFilterRedirect.propTypes = {
  username: PropTypes.string,
  redirectQuery: PropTypes.string,
  path: PropTypes.string,
};

UsernameFilterRedirect.defaultProps = {
  username: undefined,
  redirectQuery: 'username',
  path: undefined,
};

export default UsernameFilterRedirect;
