import { createFileUrlV1 } from 'core/api';
import { productTypes } from '../const';
import Podcast from '../assets/podcast_icon.svg';
import TextStory from '../assets/text_story_icon.svg';

/**
 *
 *
 * @param {Array.<Object>} files Array of objects to be filtered
 * @param {string|Array.<string>} types Arguments for filtering string or array of strings
 * @return {Array.<Object>} Array of filtered objects
 */
export const filterFilesByType = (files, types) => {
  if (Array.isArray(files)) {
    const filteredFiles = files.filter((file) => {
      if (typeof types === 'string') {
        return file.file.mimeType.includes(types);
      }

      if (Array.isArray(types)) {
        const findType = types.find((type) => file.file.mimeType.includes(type));
        if (findType) {
          return true;
        }
      }

      return false;
    });
    return filteredFiles;
  }

  return [];
};

export /**
 *
 *
 * @param {Array.<Object>} files Array of file objects
 * @return {Object} Object that contains thumbnail key
 */
const createThumbnailLink = (files) => {
  if (Array.isArray(files) && files.length > 0) {
    const fileWithThumb = files.find((file) => {
      if (file.thumb) {
        return true;
      }
      return false;
    });

    return createFileUrlV1(fileWithThumb?.thumb.nameOnServer);
  }
  return '';
};

export const getProductThumbnail = (product = {}) => {
  let thumbnail = createThumbnailLink(product.file);
  if (!thumbnail) {
    switch (product.type) {
      case productTypes.podcast.value:
        return Podcast;
      case productTypes.textStory.value:
        return TextStory;
      default:
        return '';
    }
  }
  return thumbnail;
};

export const createVideoLinkHsl = (file) => {
  if (file && file.mimeType.includes('video') && file.hslName) {
    if (/^.*(.m3u8)$/i.test(file.hslName)) {
      return createFileUrlV1(file.hslName);
    }
    return createFileUrlV1(file.hslName) + '.m3u8';
  }
  return;
};

export const createVideoLinkMp4 = (file) => {
  if (file && file.mimeType.includes('video') && file.nameOnServer) {
    if (/^.*(.mp4)$/i.test(file.nameOnServer)) {
      return createFileUrlV1(file.nameOnServer);
    }
    if (/^.*(.jpg)$/i.test(file.nameOnServer)) {
      return createFileUrlV1(file.nameOnServer.replace('.jpg', '.mp4'));
    }
    return createFileUrlV1(file.nameOnServer) + '.mp4';
  }
  return;
};
