import React, { Suspense, lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router';
import { ErrorBoundary } from '../../errorBoundaries';
import { LoadingFlom } from '../../components';
import { usersRoutes, usersRoutesRelative } from '../routes';
import { CheckRole } from 'features/hoc';
import { mobileUsersRoleRules } from '../hooks';

// lazyImports
const UserManagement = lazy(() =>
  import('../user-management/fragments/userManagement/UserManagement')
);
const UserDetails = lazy(() => import('../user-management/fragments/userDetails/UserDetails'));
const MerchantReview = lazy(() =>
  import('../merchant-review/fragments/merchantReview/MerchantReview')
);
const MerchantDetails = lazy(() =>
  import('../merchant-review/fragments/merchantDetails/MerchantDetails')
);
const PageNotFound = lazy(() => import('../../error-pages/fragments/pageNotFound/PageNotFound'));

function Users() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingFlom />}>
        <Routes>
          <Route
            path={usersRoutesRelative.USER_DETAILS}
            element={
              <CheckRole {...mobileUsersRoleRules.menu}>
                <UserDetails />
              </CheckRole>
            }
          />
          <Route
            path={usersRoutesRelative.USER_MANAGEMENT}
            element={
              <CheckRole {...mobileUsersRoleRules.menu}>
                <UserManagement />
              </CheckRole>
            }
          />

          <Route
            path={usersRoutesRelative.MERCHANT_REVIEW_DETAILS}
            element={
              <CheckRole {...mobileUsersRoleRules.menu}>
                <MerchantDetails />
              </CheckRole>
            }
          />

          <Route
            path={usersRoutesRelative.MERCHANT_REVIEW}
            element={
              <CheckRole {...mobileUsersRoleRules.menu}>
                <MerchantReview />
              </CheckRole>
            }
          />

          <Route path="/" element={<Navigate to={usersRoutes.USER_MANAGEMENT} />} />

          <Route path={usersRoutesRelative[404]} element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}

export default Users;
