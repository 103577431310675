import { useCheckRole } from 'features/hoc';
import { supportRoleRules } from './supportRoleRules';

export function useShowSupport() {
  const showSupport = {
    menu: useCheckRole(supportRoleRules.menu),
    supportTickets: useCheckRole(supportRoleRules.supportTickets),
    other: useCheckRole(supportRoleRules.other),

    transactions: useCheckRole(supportRoleRules.transactions),
    bannedIps: useCheckRole(supportRoleRules.bannedIps),
    bannedPhones: useCheckRole(supportRoleRules.bannedPhones),
    transactionLimits: useCheckRole(supportRoleRules.transactionLimits),
    pushNotifications: useCheckRole(supportRoleRules.pushNotifications),
    settings: useCheckRole(supportRoleRules.settings),
    conversionRates: useCheckRole(supportRoleRules.conversionRates),
    promotions: useCheckRole(supportRoleRules.promotions),
  };

  return showSupport;
}
