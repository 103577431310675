import React from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';

function AppropriateForKids({ appropriateForKids }) {
  if (appropriateForKids) {
    return <Tag color="green">Yes</Tag>;
  }
  return <Tag color="red">No</Tag>;
}

AppropriateForKids.propTypes = {
  appropriateForKids: PropTypes.bool,
};

AppropriateForKids.defaultProps = {
  appropriateForKids: false,
};

export default AppropriateForKids;
