import { transactionTypes, paymentMethods, sourceTypes } from 'features/support/transactions/const';
import { namesFromTypes } from 'features/helpers';

const transactionTypeNames = namesFromTypes(transactionTypes);
export function getType(type) {
  return transactionTypeNames[type] ? transactionTypeNames[type] : 'unknown';
}

const paymentMethodNames = namesFromTypes(paymentMethods);

export function getPaymentMethod(method) {
  return paymentMethodNames[method] ? paymentMethodNames[method] : 'unknown';
}

const sourceNames = namesFromTypes(sourceTypes);
export function getSource(senderType) {
  return sourceNames[senderType] ? sourceNames[senderType] : 'unknown';
}
