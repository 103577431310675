import React, { useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import PropTypes from 'prop-types';
import styles from './Breadcrumbs.module.scss';

function Breadcrumbs() {
  const { breadcrumbsDisabled, breadcrumbsLabels, breadcrumbsLinks, breadcrumbsStates } =
    useSelector((state) => state.navigation);

  const breadcrumbItems = useMemo(
    () =>
      breadcrumbsLinks.map((link, index) => {
        let search = '';
        if (breadcrumbsStates[index]?.prevSearch) {
          search = breadcrumbsStates[index].prevSearch;
        }
        return {
          key: link ? link : breadcrumbsLabels[index],
          title: (
            <Link
              className={styles.breadcrumbLink}
              to={{
                pathname: link ? link : undefined,
                search: search,
                state: breadcrumbsStates[index],
              }}
            >
              {breadcrumbsLabels[index] ? breadcrumbsLabels[index] : breadcrumbsLinks[index]}
            </Link>
          ),
        };
      }),
    [breadcrumbsLabels, breadcrumbsLinks, breadcrumbsStates]
  );

  if (breadcrumbsDisabled) {
    return <div className={styles.emptyNavigation} />;
  }

  if (breadcrumbsLinks.length === 0) {
    return <div className={styles.emptyNavigation} />;
  }

  return <Breadcrumb className={styles.breadcrumbNavigation} items={breadcrumbItems} />;
}

Breadcrumbs.propTypes = {
  breadcrumbsLabels: PropTypes.arrayOf(PropTypes.string),
  breadcrumbsLinks: PropTypes.arrayOf(PropTypes.string),
  breadcrumbsStates: PropTypes.arrayOf(PropTypes.object),
  breadcrumbsDisabled: PropTypes.bool,
};

Breadcrumbs.defaultProps = {
  breadcrumbsLabels: [],
  breadcrumbsLinks: [],
  breadcrumbsStates: [],
  breadcrumbsDisabled: false,
};

export default memo(Breadcrumbs);
