import { CompassFilled } from '@ant-design/icons';
import { mainLabels, mainRoutes } from 'features/main/routes';
import { discoverLabels, discoverRoutes } from './routes';
import { useShowDiscover } from './hooks';

export const useDiscoverSider = () => {
  const showDiscover = useShowDiscover();

  if (showDiscover.menu) {
    return [
      {
        // type: 'subMenu',
        label: mainLabels[mainRoutes.DISCOVER],
        key: mainRoutes.DISCOVER,
        icon: <CompassFilled />,
        children: [
          showDiscover.products && {
            label: discoverLabels[discoverRoutes.PRODUCTS],
            key: discoverRoutes.PRODUCTS,
          },
          showDiscover.videos && {
            label: discoverLabels[discoverRoutes.VIDEOS],
            key: discoverRoutes.VIDEOS,
          },
          showDiscover.audio && {
            label: discoverLabels[discoverRoutes.PODCASTS],
            key: discoverRoutes.PODCASTS,
          },
          showDiscover.writings && {
            label: discoverLabels[discoverRoutes.TEXT_STORIES],
            key: discoverRoutes.TEXT_STORIES,
          },
          showDiscover.expos && {
            label: discoverLabels[discoverRoutes.EXPOS],
            key: discoverRoutes.EXPOS,
          },
          showDiscover.productCategoryManagement && {
            label: discoverLabels[discoverRoutes.CATEGORY_MANAGEMENT],
            key: discoverRoutes.CATEGORY_MANAGEMENT,
          },
        ],
      },
    ];
  }
  return [];
};
