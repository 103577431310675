import React, { useState } from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import styles from './EditDeleteButtons.module.scss';

function EditDeleteButtons({ onEdit, onDelete, isDeleting, title }) {
  const [isVisible, setIsVisible] = useState(false);

  const onDeleteHandler = (event) => {
    event.stopPropagation();
    setTimeout(() => {
      setIsVisible(false);
    }, 1);
    onDelete();
  };

  const onEditHandler = (event) => {
    event.stopPropagation();
    onEdit();
  };

  const showConfirmationWindow = () => {
    setIsVisible(true);
  };
  const hideConfirmationWindow = () => {
    setIsVisible(false);
  };

  return (
    <div className={styles.container}>
      <Popconfirm
        title={title}
        open={isVisible || isDeleting}
        onConfirm={onDeleteHandler}
        okButtonProps={{
          loading: isDeleting,
        }}
        onCancel={hideConfirmationWindow}
      >
        <DeleteOutlined className={styles.delete} onClick={showConfirmationWindow} />
      </Popconfirm>
      <EditOutlined className={styles.edit} onClick={onEditHandler} />
    </div>
  );
}

EditDeleteButtons.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
  title: PropTypes.string,
};

EditDeleteButtons.defaultProps = {
  onEdit: () => {},
  onDelete: () => {},
  isDeleting: false,
  title: 'Are you sure you would like to delete this entry?',
};

export default EditDeleteButtons;
