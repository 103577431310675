import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { AuthLayout } from 'features/layout';
import { authRoutes } from '../../../routes';
import fullLogo from '../../../assets/full_logo.svg';
import { SignUpForm } from '../../../sign-in/components';
import { signUpForm } from '../../api/singUpApi';
import styles from './SignUp.module.scss';

function SignUp() {
  const navigate = useNavigate();
  const [error, setError] = useState({});

  const createForm = useMutation(({ form }) => signUpForm(form), {
    onMutate: () => {
      setError({});
    },
    onSuccess: (response, { form }) => {
      navigate(authRoutes.EMAIL_CONFIRMATION_CODE, {
        state: { form: form, userId: response.userId },
      });
    },
    onError: (error) => {
      switch (error.code) {
        case 443001:
          setError({ username: true });
          break;
        case 443388:
          setError({ email: true });
          break;
        default:
          break;
      }
    },
  });

  const singInHandler = () => {
    navigate(authRoutes.SIGN_IN);
  };

  const resetErrorHandler = () => {
    if (Object.keys(error).length > 0) {
      setError({});
    }
  };

  return (
    <AuthLayout
      header={{
        rightContent: (
          <Button onClick={singInHandler} type="link">
            Sign in
          </Button>
        ),
      }}
    >
      <Row justify="center" className={styles['image-container']}>
        <Col>
          <img src={fullLogo} alt="logo" />
        </Col>
      </Row>

      <Row justify="center">
        <Col className={styles['form-container']} flex={1}>
          <SignUpForm
            onFinish={createForm.mutate}
            isLoading={createForm.isLoading}
            error={error}
            resetError={resetErrorHandler}
            mutationError={createForm.error}
          />
        </Col>
      </Row>
    </AuthLayout>
  );
}

export default SignUp;
