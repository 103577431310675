import { useShowSupportQrios, useSupportQriosSider } from 'features/support_qrios';

export const useQriosSider = () => {
  const showSupportQrios = useShowSupportQrios();

  const supportQriosSider = useSupportQriosSider();

  if (showSupportQrios.menu) {
    return [
      { type: 'divider' },
      {
        type: 'group',
        label: 'QRIOS',
        key: 'QRIOS',
        className: 'sider-section-text',
      },
      ...supportQriosSider,
    ];
  }
  return [];
};
