// https://ant.design/docs/react/customize-theme

export const theme = {
  token: {
    // Seed Token
    colorPrimary: '#18c67f',
    colorLink: '#18c67f',
  },
  components: {
    Layout: {
      siderBg: '#fff',
    },
  },
};
