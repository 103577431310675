import { mainRoutes, generateAbsoluteRoutes } from 'features/main/routes';

export const logsRoutesRelative = {
  404: '/*',
  PAYMENT: '/payment',
};

export const logsRoutes = generateAbsoluteRoutes(logsRoutesRelative, mainRoutes.LOGS);

export const logsLabels = {
  [logsRoutes.PAYMENT]: 'Payment changes',
};

export const breadcrumbLabels = {
  payment: ['flom v2', logsLabels[logsRoutes.PAYMENT]],
};

export const breadcrumbLinks = {
  payment: [undefined, logsRoutes.PAYMENT],
};
