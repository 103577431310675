import React from 'react';
import { Typography } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useParametersFromLocationSearch } from 'core/hooks';
import { paramsFromQuery } from 'core/api';

function RedirectFilterItem({ content, redirectQuery, path, addToQuery }) {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useParametersFromLocationSearch();

  const onClickHandler = (event) => {
    event.stopPropagation();

    if (addToQuery) {
      let queryParams = { ...searchParams, [redirectQuery]: content };
      if (searchParams.page) {
        queryParams.page = 1;
      }

      navigate(
        {
          pathname: path ? path : location.pathname,
          search: paramsFromQuery(queryParams).toString(),
        },
        { replace: true }
      );
    } else {
      navigate(
        {
          pathname: path ? path : location.pathname,
          search: paramsFromQuery({ [redirectQuery]: content }).toString(),
        },
        { replace: true }
      );
    }
  };

  return (
    <Typography.Link onClick={onClickHandler}>
      <u>{content}</u>
    </Typography.Link>
  );
}

RedirectFilterItem.propTypes = {
  content: PropTypes.string.isRequired,
  redirectQuery: PropTypes.string.isRequired,
  path: PropTypes.string,
  addToQuery: PropTypes.bool,
};

RedirectFilterItem.defaultProps = {
  path: '',
  addToQuery: true,
};

export default RedirectFilterItem;
