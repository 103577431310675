import { roles } from 'features/hoc';

export const supportRoleRules = {
  menu: { min: roles.admin.value, include: [roles.supportTicket.value] },
  supportTickets: { min: roles.admin.value, include: [roles.supportTicket.value] },
  other: { min: roles.admin.value },

  transactions: { min: roles.admin.value },
  bannedIps: { min: roles.admin.value },
  bannedPhones: { min: roles.admin.value },
  transactionLimits: { min: roles.admin.value },
  pushNotifications: { min: roles.admin.value },
  settings: { min: roles.admin.value },
  conversionRates: { min: roles.admin.value },
  promotions: { min: roles.admin.value },
};
