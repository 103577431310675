import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import FlagFromPhoneNumber from '../flagFromPhoneNumber/FlagFromPhoneNumber';

function PhoneNumberWithFlag({ phoneNumber }) {
  if (!phoneNumber) {
    return (
      <>
        <FlagFromPhoneNumber phoneNumber={phoneNumber} />
        <Typography.Text>Guest user</Typography.Text>
      </>
    );
  }

  return (
    <>
      <FlagFromPhoneNumber phoneNumber={phoneNumber} />
      <Typography.Text>{phoneNumber}</Typography.Text>
    </>
  );
}

PhoneNumberWithFlag.propTypes = {
  phoneNumber: PropTypes.string,
};

export default PhoneNumberWithFlag;
