import { flomV2, checkResponse, formDataFromObject } from 'core/api';
import { roleNameFromValue } from 'features/hoc';

export const getUserDetails = async (userId) => {
  const response = await flomV2.get(`/api/v2/admin-page/users/${userId}`);

  if (checkResponse(response)) {
    return {
      ...response.data.data.user,
      roleName: roleNameFromValue(response.data.data.user.role),
    };
  }
};

export const patchUserDetails = async (data) => {
  const formData = formDataFromObject(data);

  const response = await flomV2.patch(`/api/v2/admin-page/users`, formData);

  if (checkResponse(response)) {
    return {
      ...response.data.data.user,
      roleName: roleNameFromValue(response.data.data.user.role),
    };
  }
};
