import React, { useEffect } from 'react';
import { Select, Form, Button, Input } from 'antd';
import PropTypes from 'prop-types';
import styles from './UsersFilters.module.scss';
import './antdOverride.scss';
import { useParametersFromLocationSearch } from 'core/hooks';

function UsersFilters({ onFilterChangeHandler, isLoading }) {
  const [form] = Form.useForm();
  const searchParams = useParametersFromLocationSearch();

  useEffect(() => {
    if (searchParams) {
      form.resetFields();
      form.setFieldsValue(searchParams);
    }
  }, [searchParams, form]);

  const onSubmitHandler = () => {
    const filter = form.getFieldsValue();
    const filterKeys = Object.keys(filter);
    let filteredFilter = {};
    filterKeys.map((filterKey) => {
      if (filter[filterKey]) {
        filteredFilter[filterKey] = filter[filterKey];
      }
      return null;
    });

    onFilterChangeHandler(filteredFilter);
  };

  const onClearHandler = () => {
    form.resetFields();
    onFilterChangeHandler();
  };

  return (
    <div className="override-antd">
      <Form form={form} className={styles.container} onFinish={onSubmitHandler}>
        <Form.Item name="userOrPhone" label="User:">
          <Input placeholder="Username or Phone number" />
        </Form.Item>

        <Form.Item name="blockStatus" label="Block status:">
          <Select placeholder="Select user block status">
            <Select.Option value="">All</Select.Option>
            <Select.Option value="active">Active</Select.Option>
            <Select.Option value="blocked">Blocked</Select.Option>
          </Select>
        </Form.Item>

        {/* <Form.Item name="podcast" label="Featured Podcaster:">
          <Select placeholder="Select user status">
            <Select.Option value="featured">Featured</Select.Option>
            <Select.Option value="normal">Normal</Select.Option>
          </Select>
        </Form.Item> */}

        {/* <Form.Item name="story" label="Featured Storyteller:">
          <Select placeholder="Select user status">
            <Select.Option value="featured">Featured</Select.Option>
            <Select.Option value="normal">Normal</Select.Option>
          </Select>
        </Form.Item> */}

        <Form.Item name="all" label="Featured:">
          <Select placeholder="Select user status">
            <Select.Option value="">All</Select.Option>
            <Select.Option value="featured">Featured</Select.Option>
            <Select.Option value="normal">Normal</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item name="deviceType" label="Device:">
          <Select placeholder="Select device">
            <Select.Option value="">All</Select.Option>
            <Select.Option value="web">Web</Select.Option>
            <Select.Option value="android">Android</Select.Option>
            <Select.Option value="ios">iOS</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item name="userNameChanged" label="Username set:">
          <Select placeholder="All">
            <Select.Option value="">All</Select.Option>
            <Select.Option value="true">Username changed</Select.Option>
            <Select.Option value="false">Flomer</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item name="hasLoggedIn" label="Login status:">
          <Select placeholder="User login status">
            <Select.Option value="">All</Select.Option>
            <Select.Option value="1">At lest once</Select.Option>
            <Select.Option value="2">Never</Select.Option>
            <Select.Option value="3">Old user</Select.Option>
            <Select.Option value="4">Shadow user</Select.Option>
          </Select>
        </Form.Item>

        <div className={styles.buttonContainer}>
          <Button
            className={styles.filterButton}
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Filter
          </Button>
          <Button className={styles.clearButton} danger type="dashed" onClick={onClearHandler}>
            Clear
          </Button>
        </div>
      </Form>
    </div>
  );
}

UsersFilters.propTypes = {
  onFilterSubmitHandler: PropTypes.func,
  isLoading: PropTypes.bool,
};

UsersFilters.defaultProps = {
  onFilterSubmitHandler: () => {},
  isLoading: false,
};

export default UsersFilters;
