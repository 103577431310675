import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Row, Col, Button, Typography, message } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { AuthLayout } from 'features/layout';
import { mainRoutes } from 'features/main';
import { authRoutes } from '../../../routes';
import fullLogo from '../../../assets/full_logo.svg';
import styles from './EmailCodeConfirmation.module.scss';
import { EmailCodeForm, ChangeEmailModal } from '../../components';
import {
  emailVerificationCode,
  resendEmailApi,
  changeVerificationEmailApi,
} from '../../api/singUpApi';

function EmailCodeConfirmation(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isModal, setIsModal] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState({});

  const emailVerification = useMutation(
    ({ emailCode }) => emailVerificationCode(emailCode, location.state?.userId),
    {
      onMutate: () => {
        setError({});
      },
      onSuccess: () => {
        // history.replace(mainRoutes.AUTH, []);
        navigate(mainRoutes.AUTH, { replace: true });
      },
      onError: () => {
        setError({ emailCode: true });
      },
    }
  );

  const resendEmail = useMutation(() => resendEmailApi(location.state?.userId), {
    onMutate: () => {
      message.loading({
        content: 'Resending email.',
        key: 'resendEmail',
        duration: 100,
      });
    },
    onSuccess: () => {
      message.success({
        content: 'Email successfully send.',
        key: 'resendEmail',
        duration: 4,
      });
    },
    onError: () => {
      message.error({
        content: 'Resending email failed.',
        key: 'resendEmail',
        duration: 4,
      });
    },
  });

  const changeVerificationEmail = useMutation(
    ({ email }) => changeVerificationEmailApi(email, location.state?.userId),
    {
      onMutate: () => {
        message.loading({
          content: 'Changing verification email.',
          key: 'changeVerificationEmail',
          duration: 100,
        });
      },
      onSuccess: (response, variables) => {
        message.success({
          content: `Email has been send to: ${variables.email}`,
          key: 'changeVerificationEmail',
          duration: 4,
        });
        setIsModal(false);
        setEmail(variables.email);
      },
      onError: () => {
        message.error({
          content: 'Changing verification email failed.',
          key: 'changeVerificationEmail',
          duration: 4,
        });
      },
    }
  );

  const singInHandler = () => {
    navigate(authRoutes.SIGN_IN, { replace: true });
  };

  const onBackHandler = () => {
    navigate(authRoutes.SIGN_UP, { state: { form: location.state?.form } });
  };

  const onFinishHandler = (emailCode) => {
    emailVerification.mutate({ emailCode });
  };

  const onResendCodeHandler = () => {
    resendEmail.mutate();
  };

  const onUpdateEmailAddressHandler = (email) => {
    changeVerificationEmail.mutate({ email });
  };

  const resetErrorHandler = () => {
    if (Object.keys(error).length > 0) {
      setError({});
    }
  };

  return (
    <AuthLayout
      header={{
        rightContent: (
          <Button onClick={singInHandler} type="link">
            Sign in
          </Button>
        ),
      }}
    >
      <Row justify="center" className={styles['image-container']}>
        <Col>
          <img src={fullLogo} alt="logo" />
        </Col>
      </Row>

      <Row justify="center">
        <Col className={styles['form-container']} flex={1}>
          <EmailCodeForm
            onBack={onBackHandler}
            onFinish={onFinishHandler}
            isLoading={emailVerification.isLoading}
            email={email || location.state?.form.email}
            error={error}
            resetError={resetErrorHandler}
          />
        </Col>
      </Row>

      <Row justify="center">
        <Col>
          <Typography.Text>
            Didn't get your email?{' '}
            <Typography.Link onClick={onResendCodeHandler} disabled={resendEmail.isLoading}>
              Resend the code{' '}
            </Typography.Link>
            or
            <Typography.Link onClick={() => setIsModal(true)}>
              {' '}
              update your email address.
            </Typography.Link>
          </Typography.Text>
        </Col>
      </Row>

      <ChangeEmailModal
        visible={isModal}
        onCancel={() => setIsModal(false)}
        onFinish={onUpdateEmailAddressHandler}
        isLoading={changeVerificationEmail.isLoading}
      />
    </AuthLayout>
  );
}

export default EmailCodeConfirmation;
