import { useShowSupport, useSupportV2Sider } from 'features/support';
import { useShowAdminUsers, useAdminUsersSider } from 'features/admin-users';
import { useShowLogs, useLogsSider } from 'features/logs';

export const useFlomV2Sider = () => {
  const showSupport = useShowSupport();
  const showAdminUsers = useShowAdminUsers();
  const showLogs = useShowLogs();

  const supportV2Sider = useSupportV2Sider();
  const adminUsersSider = useAdminUsersSider();
  const logsV2Sider = useLogsSider();

  if (showSupport.menu || showAdminUsers.menu || showLogs.menu) {
    return [
      { type: 'divider' },
      {
        type: 'group',
        label: 'Flom v2',
        key: 'Flom v2',
        className: 'sider-section-text',
      },
      ...adminUsersSider,
      ...supportV2Sider,
      ...logsV2Sider,
    ];
  }

  return [];
};
