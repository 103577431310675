import React, { useCallback } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { useDispatch } from 'react-redux';
import styles from './Content.module.scss';
import Breadcrumbs from '../../fragments/breadcrumbs/Breadcrumbs';
import { setContent } from '../../layoutSlice';

function Content({ children }) {
  const dispatch = useDispatch();

  const onResizeHandler = useCallback(
    (width, height) => {
      dispatch(setContent({ width, height }));
    },
    [dispatch]
  );

  return (
    <>
      <Breadcrumbs />
      <ReactResizeDetector onResize={onResizeHandler} refreshMode="throttle">
        <div id="main" className={styles.layoutContent}>
          {children}
        </div>
      </ReactResizeDetector>
    </>
  );
}

export default Content;
