import React, { useMemo } from 'react';
import { Tooltip, Typography } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useParametersFromLocationSearch } from 'core/hooks';
import { paramsFromQuery } from 'core/api';

function UsernameFilterItem({
  content,
  redirectQuery = 'userOrPhone',
  path = '',
  addToQuery = true,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useParametersFromLocationSearch();

  const onClickHandler = (event) => {
    event.stopPropagation();

    if (addToQuery) {
      let queryParams = { ...searchParams, [redirectQuery]: content };
      if (searchParams.page) {
        queryParams.page = 1;
      }

      navigate(
        {
          pathname: path ? path : location.pathname,
          search: paramsFromQuery(queryParams).toString(),
        },
        { replace: true }
      );
    } else {
      navigate(
        {
          pathname: path ? path : location.pathname,
          search: paramsFromQuery({ [redirectQuery]: content }).toString(),
        },
        { replace: true }
      );
    }
  };

  const { text, type } = useMemo(() => {
    if (content.startsWith('Flomer_')) {
      const contentArray = content.split('_');

      if (contentArray[1]) {
        return { text: contentArray[1], type: 'secondary' };
      }
    }

    if (content.startsWith('Deleted_user_')) {
      const contentArray = content.split('_');

      if (contentArray[2]) {
        return { text: contentArray[2], type: 'warning' };
      }
    }

    return { text: content, type: 'primary' };
  }, [content]);

  if (type === 'primary') {
    return (
      <Typography.Link onClick={onClickHandler} type={type}>
        <u>{text}</u>
      </Typography.Link>
    );
  }

  return (
    <Tooltip title={content} placement="top">
      <Typography.Link onClick={onClickHandler} type={type}>
        <u>{text}</u>
      </Typography.Link>
    </Tooltip>
  );
}

UsernameFilterItem.propTypes = {
  content: PropTypes.string.isRequired,
  redirectQuery: PropTypes.string,
  path: PropTypes.string,
  addToQuery: PropTypes.bool,
};

export default UsernameFilterItem;
