import React, { useState, useMemo, useRef } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import RenderSmoothImage from 'render-smooth-image-react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/scss/alice-carousel.scss';
import { createFileUrlV1 } from 'core/api';
import styles from './Gallery.module.scss';
import ImageWithLoading from './ImageWithLoading';
import { VideoPlayer } from '..';

const createItems = (files) => {
  const items = files.map((file) => {
    if (file.file.mimeType.includes('video')) {
      return {
        key: file.file.nameOnServer,
        thumb: createFileUrlV1(file.thumb.nameOnServer),
        type: 'video',
        url: createFileUrlV1(file.file.hslName) + '.m3u8',
        mp4: createFileUrlV1(file.file.nameOnServer),
      };
    }
    return {
      key: file.file.nameOnServer,
      thumb: createFileUrlV1(file.thumb.nameOnServer),
      type: 'image',
      url: createFileUrlV1(file.file.nameOnServer),
    };
  });

  return items;
};
function Gallery({ files }) {
  const carouselRef = useRef(null);

  const [mainIndex, setMainIndex] = useState(0);
  const [mainAnimation, setMainAnimation] = useState(false);
  const [thumbIndex, setThumbIndex] = useState(0);
  const [thumbAnimation, setThumbAnimation] = useState(false);

  const mainItems = useMemo(() => {
    const items = createItems(files);
    const mainItems = items.map((item) => {
      if (item.type === 'video') {
        return <VideoPlayer url={item.url} />;
      }
      return (
        <div className={styles.videoImageContainer}>
          <ImageWithLoading src={item.url} />
        </div>
      );
    });
    return mainItems;
  }, [files]);

  const thumbItems = useMemo(() => {
    const items = createItems(files);
    return items.map((item, index) => (
      <div
        className={styles.thumbItem}
        onClick={() => {
          setThumbIndex(index);
          // setThumbAnimation(true);

          // may cause some unexpected behavior on touchscreen
          setMainIndex(index);
        }}
      >
        <RenderSmoothImage src={item.thumb} alt="thumb" />
      </div>
    ));
  }, [files]);

  if (files.length === 0) {
    return null;
  }

  const slideNext = () => {
    if (!thumbAnimation && thumbIndex < thumbItems.length - 1) {
      setThumbAnimation(true);
      setThumbIndex(thumbIndex + 1);

      setMainIndex(mainIndex + 1);
    }
  };

  const slidePrev = () => {
    if (!thumbAnimation && thumbIndex > 0) {
      setThumbAnimation(true);
      setThumbIndex(thumbIndex - 1);

      setMainIndex(mainIndex - 1);
    }
  };

  const syncMainBeforeChange = () => {
    setMainAnimation(true);
  };

  const syncMainAfterChange = (e) => {
    setMainAnimation(false);

    if (e.type === 'action') {
      setThumbIndex(e.item);
      setThumbAnimation(false);
    } else {
      // setMainIndex(thumbIndex);

      // may cause some unexpected behavior on touchscreen
      carouselRef.current.slideTo(mainIndex);
    }
  };

  const syncThumbs = (e) => {
    setThumbIndex(e.item);
    setThumbAnimation(false);

    if (!mainAnimation) {
      setMainIndex(e.item);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.galleryContainer}>
        {mainItems.length === 1 && mainItems[0]}

        {mainItems.length > 1 && (
          <>
            <AliceCarousel
              ref={carouselRef}
              activeIndex={mainIndex}
              animationType="fadeout"
              animationDuration={400}
              disableDotsControls
              disableButtonsControls
              items={mainItems}
              mouseTracking={!thumbAnimation}
              onSlideChange={syncMainBeforeChange}
              onSlideChanged={syncMainAfterChange}
              touchTracking={!thumbAnimation}
              keyboardNavigation
            />

            {mainItems.length > 1 && (
              <>
                <div className={styles.arrows} position="left">
                  <button onClick={slidePrev} disabled={mainIndex <= 0}>
                    <LeftOutlined />
                  </button>
                </div>

                <div className={styles.arrows} position="right">
                  <button onClick={slideNext} disabled={mainIndex >= mainItems.length - 1}>
                    <RightOutlined />
                  </button>
                </div>
              </>
            )}

            {mainItems.length > 1 && (
              <div className={styles.thumbContainer}>
                <AliceCarousel
                  items={thumbItems}
                  activeIndex={thumbIndex}
                  autoWidth
                  disableDotsControls
                  disableButtonsControls
                  mouseTracking={false}
                  onSlideChanged={syncThumbs}
                  touchTracking={!mainAnimation}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

Gallery.propTypes = {
  files: PropTypes.array,
};

Gallery.defaultProps = {
  files: [],
};

export default Gallery;
