import { useShowDiscover, useDiscoverSider } from 'features/discover';
import { useShowSupportV1, useSupportV1Sider } from 'features/support_v1';
import { useShowMobileUsers, useMobileUsersSider } from 'features/mobile-users';
import { useShowLogs, useLogsSider } from 'features/logs_v1';

export const useFlomV1Sider = () => {
  const showDiscover = useShowDiscover();
  const showSupportV1 = useShowSupportV1();
  const showUserManagement = useShowMobileUsers();
  const showLogs = useShowLogs();

  const discoverSider = useDiscoverSider();
  const supportV1Sider = useSupportV1Sider();
  const mobileUsersSider = useMobileUsersSider();
  const logsSider = useLogsSider();

  if (showDiscover.menu || showUserManagement.menu || showLogs.menu || showSupportV1.menu) {
    return [
      { type: 'divider' },
      {
        type: 'group',
        label: 'Flom v1',
        key: 'Flom v1',
        className: 'sider-section-text',
      },
      ...discoverSider,
      ...supportV1Sider,
      ...mobileUsersSider,
      ...logsSider,
    ];
  }

  return [];
};
