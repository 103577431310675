import { SettingFilled } from '@ant-design/icons';
import { mainLabels, mainRoutes } from 'features/main/routes';
import { supportV1Labels, supportV1Routes, supportV1SubMenu } from './routes';
import { useShowSupportV1 } from './hooks';

export const useSupportV1Sider = () => {
  const showSupportV1 = useShowSupportV1();

  if (showSupportV1.menu) {
    return [
      {
        // type: 'subMenu',
        label: mainLabels[mainRoutes.SUPPORT_V1],
        key: mainRoutes.SUPPORT_V1,
        icon: <SettingFilled />,
        children: [
          showSupportV1.supportTickets && {
            label: supportV1Labels[supportV1Routes.SUPPORT_TICKETS],
            key: supportV1Routes.SUPPORT_TICKETS,
          },
          showSupportV1.transactions && {
            label: supportV1Labels[supportV1Routes.TRANSACTIONS],
            key: supportV1Routes.TRANSACTIONS,
          },
          showSupportV1.flomoji && {
            label: supportV1Labels[supportV1Routes.FLOMOJI],
            key: supportV1Routes.FLOMOJI,
          },
          showSupportV1.sounds && {
            label: supportV1Labels[supportV1Routes.SOUNDS],
            key: supportV1Routes.SOUNDS,
          },
          showSupportV1.smsData && {
            label: supportV1Labels[supportV1Routes.SMSDATA],
            key: supportV1Routes.SMSDATA,
          },
          showSupportV1.blockedThirdPartyProducts && {
            label: supportV1Labels[supportV1Routes.BLOCKED_THIRD_PARTY_PRODUCTS],
            key: supportV1Routes.BLOCKED_THIRD_PARTY_PRODUCTS,
          },
          showSupportV1.customerActivation && {
            label: supportV1Labels[supportV1Routes.CUSTOMER_ACTIVATION],
            key: supportV1Routes.CUSTOMER_ACTIVATION,
          },
          showSupportV1.taxes && {
            label: supportV1Labels[supportV1Routes.TAXES],
            key: supportV1Routes.TAXES,
          },
          showSupportV1.chatGPT && {
            label: supportV1Labels[supportV1Routes.BANNED_CHATGPT_COUNTRIES],
            key: supportV1Routes.BANNED_CHATGPT_COUNTRIES,
          },
          showSupportV1.IDApplication && {
            label: supportV1Labels[supportV1Routes.ID_APPLICATION],
            key: supportV1Routes.ID_APPLICATION,
          },
          showSupportV1.sats && {
            label: supportV1Labels[supportV1Routes.SATS_BANNED_COUNTRIES],
            key: supportV1Routes.SATS_BANNED_COUNTRIES,
          },
          showSupportV1.sendSMS && {
            label: supportV1Labels[supportV1Routes.SEND_SMS],
            key: supportV1Routes.SEND_SMS,
          },
          showSupportV1.contactUs && {
            label: supportV1Labels[supportV1Routes.CONTACT_US],
            key: supportV1Routes.CONTACT_US,
          },
          (showSupportV1.credits ||
            showSupportV1.balanceEmoji ||
            showSupportV1.sprayAmount ||
            showSupportV1.conversionRates ||
            showSupportV1.addCredits) && {
            label: supportV1Labels[supportV1Routes.CREDITS],
            key: supportV1SubMenu.CREDITS,
            children: [
              showSupportV1.credits && {
                label: supportV1Labels[supportV1Routes.CREDITS],
                key: supportV1Routes.CREDITS,
              },
              showSupportV1.balanceEmoji && {
                label: supportV1Labels[supportV1Routes.CREDITS_BALANCE_EMOJI],
                key: supportV1Routes.CREDITS_BALANCE_EMOJI,
              },
              showSupportV1.sprayAmount && {
                label: supportV1Labels[supportV1Routes.SPRAY_AMOUNT],
                key: supportV1Routes.SPRAY_AMOUNT,
              },
              showSupportV1.conversionRates && {
                label: supportV1Labels[supportV1Routes.CONVERSION_RATES],
                key: supportV1Routes.CONVERSION_RATES,
              },
              showSupportV1.addCredits && {
                label: supportV1Labels[supportV1Routes.ADD_CREDITS],
                key: supportV1Routes.ADD_CREDITS,
              },
              showSupportV1.creditLimits && {
                label: supportV1Labels[supportV1Routes.CREDIT_LIMITS],
                key: supportV1Routes.CREDIT_LIMITS,
              },
            ],
          },
          (showSupportV1.payout || showSupportV1.payoutLimits) && {
            label: supportV1Labels[supportV1Routes.PAYOUT],
            key: supportV1SubMenu.PAYOUT,
            children: [
              showSupportV1.payout && {
                label: supportV1Labels[supportV1Routes.PAYOUT],
                key: supportV1Routes.PAYOUT,
              },
              showSupportV1.payoutLimits && {
                label: supportV1Labels[supportV1Routes.PAYOUT_LIMITS],
                key: supportV1Routes.PAYOUT_LIMITS,
              },
            ],
          },
          showSupportV1.fees && {
            label: supportV1Labels[supportV1Routes.FEES],
            key: supportV1Routes.FEES,
          },
          showSupportV1.stickerSets && {
            label: supportV1Labels[supportV1Routes.STICKER_SETS],
            key: supportV1Routes.STICKER_SETS,
          },
          showSupportV1.settings && {
            label: supportV1Labels[supportV1Routes.SETTINGS],
            key: supportV1Routes.SETTINGS,
          },
        ],
      },
    ];
  }

  return [];
};
