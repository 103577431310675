import { ContainerOutlined } from '@ant-design/icons';
import { mainLabels, mainRoutes } from 'features/main/routes';
import { logsLabels, logsRoutes } from './routes';
import { useShowLogs } from './hooks';

export const useLogsSider = () => {
  const showLogs = useShowLogs();

  if (showLogs.menu) {
    return [
      {
        // type: 'subMenu',
        label: mainLabels[mainRoutes.LOGS],
        key: mainRoutes.LOGS,
        icon: <ContainerOutlined />,
        children: [
          showLogs.payment && {
            label: logsLabels[logsRoutes.PAYMENT],
            key: logsRoutes.PAYMENT,
          },
        ],
      },
    ];
  }

  return [];
};
