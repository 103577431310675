import React, { useEffect } from 'react';
import { Form, Button, Row, Col } from 'antd';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import SegmentedInput from '../segmentedInput/SegmentedInput';
import ResendCounter from '../resendCounter/ResendCounter';
import { errorCodes, smsVerification, resendSms } from '../../api/authApi';

function SmsVerification({ onBack, credentials, onSignUp }) {
  const [form] = Form.useForm();

  useEffect(() => {
    // redirect after 5min
    const backTimeout = setTimeout(onBack, 300000);
    return () => {
      clearTimeout(backTimeout);
    };
  }, []);

  const smsVerificationMutation = useMutation(
    ({ tempToken, username, code }) => smsVerification(tempToken, username, code),
    {
      onSuccess: (response, { username }) => {
        onSignUp(response, username);
      },
      onError: () => {},
    }
  );

  const smsResendMutation = useMutation(
    ({ tempToken, username }) => resendSms(tempToken, username),
    {
      onSuccess: () => {},
      onError: () => {},
    }
  );

  const onFinishHandler = () => {
    const { tempToken, username } = credentials;
    smsVerificationMutation.mutate({ tempToken, username, ...form.getFieldsValue() });
  };

  const onResendHandler = () => {
    const { tempToken, username } = credentials;
    smsResendMutation.mutate({ tempToken, username });
  };

  const setValidationStatusAndHelp = (error) => {
    if (error?.code === errorCodes.smsVerificationWrong) {
      return { validateStatus: 'error', help: 'Incorrect or expired code.' };
    }
    return { validateStatus: '', help: '' };
  };

  const onInputChangeHandler = (value) => {
    if (value.length === 6 && smsVerificationMutation.isIdle) {
      onFinishHandler();
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinishHandler}>
      <Form.Item
        {...setValidationStatusAndHelp(smsVerificationMutation.error)}
        label="Verification code:"
        name="code"
        rules={[{ required: true, message: '*verification code is required' }]}
      >
        <SegmentedInput onChange={onInputChangeHandler} />
      </Form.Item>
      <ResendCounter
        countDownTime={90}
        onResend={onResendHandler}
        loading={smsResendMutation.isLoading}
      />
      <Row align="middle" justify="center">
        <Col>
          <Form.Item>
            <Button onClick={onBack}>Back</Button>
          </Form.Item>
        </Col>
        <Col style={{ width: '3rem' }} />
        <Col>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={smsVerificationMutation.isLoading}>
              Verify
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

SmsVerification.propTypes = {
  onBack: PropTypes.func,
  credentials: PropTypes.object,
  onSignUp: PropTypes.func,
};

SmsVerification.defaultProps = {
  onBack: () => {},
  credentials: {},
  onSignUp: () => {},
};

export default SmsVerification;
