import React, { useMemo } from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';

function DeviceType({ deviceType }) {
  const tagProps = useMemo(() => {
    switch (deviceType) {
      case 'android':
        return { color: 'green', children: 'Android' };
      case 'ios':
        return { color: 'gray', children: 'iOS' };
      case 'web':
        return { color: 'purple', children: 'Web' };
      default:
        return { color: 'default', children: 'Unknown' };
    }
  }, [deviceType]);

  return <Tag {...tagProps} />;
}

DeviceType.propTypes = {
  deviceType: PropTypes.oneOf(['unknown', 'android', 'ios', 'web']),
};

DeviceType.defaultProps = {
  deviceType: undefined,
};

export default DeviceType;
