import { roles } from 'features/hoc';

export const discoverRoleRules = {
  menu: { min: roles.reviewers.value },
  productCategoryManagement: { min: roles.admin.value },
  products: { min: roles.reviewers.value },
  videos: { min: roles.reviewers.value },
  audio: { min: roles.reviewers.value },
  writings: { min: roles.reviewers.value },
  expos: { min: roles.reviewers.value },

  // individual page features
  isReviewer: { min: roles.reviewers.value, max: roles.reviewers.value },
};
